import React, { createContext, useState, useContext, useCallback } from 'react';
import axiosInstance from '../services/api.js';

const UploadQueueContext = createContext();

export const useUploadQueue = () => useContext(UploadQueueContext);

export const UploadQueueProvider = ({ children }) => {
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileUrls, setFileUrls] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentChannel, setCurrentChannel] = useState({ id: null, name: '' });

  const addFilesToQueue = (files, userId, channelId, channelName) => {
    const newFiles = files.map((file) => ({ file, userId, channelId }));
    setUploadQueue((prevQueue) => [...prevQueue, ...newFiles]);
    setCurrentChannel({ id: channelId, name: channelName });
    setIsVisible(true);
  };

  const removeFileFromQueue = (fileName) => {
    setUploadQueue((prevQueue) => prevQueue.filter((item) => item.file.name !== fileName));
  };

  // const uploadFiles = useCallback(async () => {
  //   const newFileUrls = [];
  //   for (const { file, userId, channelId } of uploadQueue) {
  //     const formData = new FormData();
  //     formData.append('files', file);
  //     formData.append('userId', userId);
  //     formData.append('channelId', channelId);

  //     try {
  //       const response = await axiosInstance.post('/api/files/upload', formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //         timeout: 600000, // 10 minutes
  //         onUploadProgress: (progressEvent) => {
  //           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //           setUploadProgress((prevProgress) => ({
  //             ...prevProgress,
  //             [file.name]: percentCompleted,
  //           }));
  //         },
  //       });
  //       if (response.data && response.data.fileUrls) {
  //         newFileUrls.push(...response.data.fileUrls);
  //       }
  //       setUploadQueue((prevQueue) => prevQueue.filter((item) => item.file !== file));
  //     } catch (error) {
  //       console.error('File upload error:', error);
  //       setErrorMessage(`Failed to upload ${file.name}: ${error.message}`);
  //     }
  //   }
  //   setFileUrls((prevUrls) => [...prevUrls, ...newFileUrls]);
  //   setIsVisible(false);
  //   return newFileUrls;
  // }, [uploadQueue]);

  const uploadFile = async (file, userId, channelId) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('userId', userId);
    formData.append('channelId', channelId);

    try {
      const response = await axiosInstance.post('/api/files/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 600000,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: percentCompleted,
          }));
        },
      });

      if (response.data && response.data.fileUrls) {
        // Immediately update fileUrls when a file is done
        setFileUrls(prevUrls => [...prevUrls, ...response.data.fileUrls]);
        return response.data.fileUrls;
      }
      return [];
    } catch (error) {
      console.error('File upload error:', error);
      setErrorMessage(`Failed to upload ${file.name}: ${error.message}`);
      return [];
    }
  };

  const uploadFiles = useCallback(async () => {
    const allFileUrls = [];
    
    // Process files sequentially to ensure reliable ordering
    for (const { file, userId, channelId } of uploadQueue) {
      try {
        const urls = await uploadFile(file, userId, channelId);
        
        if (urls && urls.length > 0) {
          allFileUrls.push(...urls);
          
          // Update fileUrls state immediately after each successful upload
          setFileUrls(prevUrls => [...prevUrls, ...urls]);
          
          // Remove the completed file from the queue
          setUploadQueue(prevQueue => 
            prevQueue.filter(item => item.file.name !== file.name)
          );
  
          // Clear the progress for this file
          setUploadProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            delete newProgress[file.name];
            return newProgress;
          });
        }
      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
      }
    }
  
    // Hide the upload queue if all files are done
    if (uploadQueue.length === 0) {
      setIsVisible(false);
    }
  
    return allFileUrls;
  }, [uploadQueue]);


  const closeUploadQueue = () => {
    setIsVisible(false);
  };

  return (
    <UploadQueueContext.Provider value={{
      addFilesToQueue,
      removeFileFromQueue,
      uploadProgress,
      uploadQueue,
      uploadFiles,
      isVisible,
      fileUrls,
      errorMessage,
      currentChannel,
      closeUploadQueue // Add the closeUploadQueue method to the context
    }}>
      {children}
    </UploadQueueContext.Provider>
  );
};