import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, CircularProgress, Alert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../services/api';

const TokenTesterDebug = () => {
  const { 
    user, 
    accessToken, 
    isAuthenticated,
    accessTokenExpiration,
    refreshTokens 
  } = useAuth();
  
  const [debugInfo, setDebugInfo] = useState({
    authState: null,
    requestAttempts: [],
    consoleLogs: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authStatus, setAuthStatus] = useState({
    checking: true,
    authenticated: false,
    message: ''
  });
  const [timeRemaining, setTimeRemaining] = useState(null);

  // Check authentication status on mount and when auth state changes
  useEffect(() => {
    setAuthStatus({
      checking: false,
      authenticated: isAuthenticated && !!accessToken,
      message: isAuthenticated && accessToken 
        ? `Authenticated as ${user?.email}`
        : 'Not authenticated'
    });
  }, [isAuthenticated, accessToken, user]);

  // Log auth state changes
  useEffect(() => {
    const authState = {
      isAuthenticated,
      hasUser: !!user,
      userId: user?._id || user?.id,
      hasAccessToken: !!accessToken,
      accessTokenExpiration,
      currentTime: Date.now()
    };
    
    setDebugInfo(prev => ({
      ...prev,
      authState
    }));
    
    console.log('Auth State:', authState);
  }, [user, accessToken, isAuthenticated, accessTokenExpiration]);

  // Calculate time remaining
  useEffect(() => {
    if (!accessTokenExpiration) return;

    const timer = setInterval(() => {
      const now = new Date();
      const timeLeft = accessTokenExpiration - now.getTime();
      
      if (timeLeft <= 0) {
        clearInterval(timer);
        setTimeRemaining('Session expired');
        addLog('warning', '⚠️ Token has expired');
      } else {
        const minutes = Math.floor(timeLeft / 1000 / 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        setTimeRemaining(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [accessTokenExpiration]);

  // Add log helper
  const addLog = (type, message) => {
    const timestamp = new Date().toISOString();
    setDebugInfo(prev => ({
      ...prev,
      consoleLogs: [...prev.consoleLogs, { timestamp, type, message }]
    }));
    console.log(`[${timestamp}] ${type}:`, message);
  };

  const generateToken = async () => {
    if (!isAuthenticated || !accessToken) {
      throw new Error('Not authenticated. Please log in first.');
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const requestInfo = {
        timestamp: new Date().toISOString(),
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        userId: user?._id || user?.id,
        baseURL: axiosInstance.defaults.baseURL
      };
  
      console.log('Making request with:', requestInfo);
  
      const response = await axiosInstance.post('/api/auth/tokens/generate', null, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
        withCredentials: true
      });
  
      setDebugInfo(prev => ({
        ...prev,
        requestAttempts: [...prev.requestAttempts, {
          ...requestInfo,
          status: 'success',
          response: response.data
        }]
      }));
  
      addLog('success', '✅ Tokens generated successfully');
      await refreshTokens();
      addLog('info', '🔄 Session extended successfully');
  
      return response.data;
    } catch (error) {
      const errorInfo = {
        timestamp: new Date().toISOString(),
        error: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        responseData: error.response?.data
      };
  
      console.error('Token generation error:', errorInfo);
      setDebugInfo(prev => ({
        ...prev,
        requestAttempts: [...prev.requestAttempts, {
          error: errorInfo
        }]
      }));
  
      addLog('error', '❌ Token generation error');
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  const handleTestClick = async () => {
    if (!authStatus.authenticated) {
      setError('Please log in first');
      return;
    }
    await generateToken();
  };

  

  // Show loading state while checking auth
  if (authStatus.checking) {
    return (
      <Box p={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Typography variant="h5" gutterBottom>XX Token Testing Dashboard</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          {authStatus.authenticated ? '✓ Fully Authenticated' : '✗ Not Authenticated'}
        </Typography>
        {timeRemaining && (
          <Typography variant="caption" sx={{ opacity: 0.8 }}>
            ({timeRemaining})
          </Typography>
        )}
      </Box>

      <Box mb={2} p={2} bgcolor="grey.100" borderRadius={1}>
        <Typography variant="body2">Email: {user?.email}</Typography>
        <Typography variant="body2">ID: {user?._id || user?.id}</Typography>
        <Typography variant="body2">
          Access Token: {accessToken ? (
            <span style={{ color: 'green' }}>✓ Present</span>
          ) : (
            <span style={{ color: 'red' }}>✗ Missing</span>
          )}
        </Typography>
        <Typography variant="body2">
          Token Expires: {accessTokenExpiration ? new Date(accessTokenExpiration).toLocaleString() : 'Unknown'}
        </Typography>
        <Typography variant="body2" color={
          timeRemaining === 'Session expired' ? 'error.main' : 'text.secondary'
        }>
          Time Remaining: {timeRemaining || 'Unknown'}
        </Typography>
      </Box>

      <Button 
        variant="contained" 
        onClick={handleTestClick}
        disabled={loading || !authStatus.authenticated}
        sx={{ mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Test Token Generation'}
      </Button>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Auth State */}
      <Box mt={4}>
        <Typography variant="subtitle1">Authentication State</Typography>
        <pre style={{ 
          backgroundColor: '#f5f5f5', 
          padding: '16px', 
          borderRadius: '8px', 
          overflowX: 'auto' 
        }}>
          {JSON.stringify(debugInfo.authState, null, 2)}
        </pre>
      </Box>

      {/* Request History */}
      {debugInfo.requestAttempts.length > 0 && (
        <Box mt={4}>
          <Typography variant="subtitle1">Request History</Typography>
          {debugInfo.requestAttempts.map((attempt, index) => (
            <Box 
              key={index}
              mt={2}
              p={2}
              bgcolor={attempt.error ? 'error.light' : 'success.light'}
              borderRadius={1}
            >
              <pre style={{ 
                margin: 0,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word'
              }}>
                {JSON.stringify(attempt, null, 2)}
              </pre>
            </Box>
          ))}
        </Box>
      )}

      {/* Console Logs */}
      <Box mt={4}>
        <Typography variant="subtitle1">Console Logs</Typography>
        <pre style={{
          marginTop: '1rem',
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          borderRadius: '4px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}>
          {debugInfo.consoleLogs.map(log => {
            const date = new Date(log.timestamp);
            return (
              <div key={log.timestamp}>
                <span className="text-gray-500 text-xs">
                  {date.toLocaleTimeString()}
                </span>
                {' '}
                {log.message}
              </div>
            );
          })}
        </pre>
      </Box>
    </Box>
  );
};

export default TokenTesterDebug;