import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Divider, Button, Snackbar, Alert } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAuth } from '../contexts/AuthContext';
import TokenDisplay from './TokenDisplay';
import axiosInstance from '../services/api'; // Assuming you have this for making API calls

const AuthCheckPage = () => {
  const { user, accessToken, accessTokenExpiration, refreshTokenExpiration, refreshTokens, logout, isAuthenticated } = useAuth();
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [generatedTokens, setGeneratedTokens] = useState(null);
  const [refreshTokenData, setRefreshTokenData] = useState(null);
  const [consoleOutput, setConsoleOutput] = useState('');

  const fetchGeneratedTokens = async () => {
    try {
      const config = {
        headers: { 'Authorization': `Bearer ${accessToken}` },
        withCredentials: true
      };

      const response = await axiosInstance.get('/api/auth/generate-tokens', config);
      setGeneratedTokens(response.data);
    } catch (error) {
      console.error('Error fetching generated tokens:', error);
      setGeneratedTokens({ error: 'Failed to fetch generated tokens' });
    }
  };

  const fetchRefreshTokenData = async () => {
    try {
      const response = await axiosInstance.post('/api/auth/refresh-token', {}, {
        withCredentials: true
      });
      setRefreshTokenData(response.data);
    } catch (error) {
      console.error('Error fetching refresh token data:', error);
      setRefreshTokenData({ error: 'Failed to fetch refresh token data' });
    }
  };

  const fetchConsoleOutput = async () => {
    try {
      const response = await fetch('/console-output');
      const text = await response.text();
      setConsoleOutput(text);
    } catch (error) {
      console.error('Error fetching console output:', error);
      setConsoleOutput('Error fetching console output');
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchGeneratedTokens();
    }
    fetchConsoleOutput();
  }, [user]);

  const handleRefreshTokens = async () => {
    try {
      const result = await refreshTokens();
      setNotification({
        open: true,
        message: result ? 'Tokens refreshed successfully!' : 'Failed to refresh tokens',
        severity: result ? 'success' : 'error'
      });
      fetchRefreshTokenData();
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error refreshing tokens',
        severity: 'error'
      });
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Not available';
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 800, mx: 'auto', mt: 4, p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <AccessTimeIcon sx={{ mr: 1 }} />
        Authentication Status
      </Typography>

      <TokenDisplay title="Access Token" token={accessToken} expiration={accessTokenExpiration} />
      
      <Divider sx={{ my: 2 }} />
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2">
          <strong>Refresh Token:</strong> {refreshTokens ? 'Available' : 'Not available'}
        </Typography>
        <Typography variant="body2">
          <strong>Refresh Token Expiration:</strong> {formatDate(refreshTokenExpiration)}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={handleRefreshTokens}
          disabled={!isAuthenticated}
        >
          Refresh Tokens
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={logout}
        >
          End Session
        </Button>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Generated Tokens Data */}
      <Box>
        <Typography variant="h6">Generated Tokens Data</Typography>
        <pre style={{
          marginTop: '1rem',
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          borderRadius: '4px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}>
          {generatedTokens ? JSON.stringify(generatedTokens, null, 2) : "Loading..."}
        </pre>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Refresh Token Data */}
      <Box>
        <Typography variant="h6">Refresh Token Data</Typography>
        <pre style={{
          marginTop: '1rem',
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          borderRadius: '4px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}>
          {refreshTokenData ? JSON.stringify(refreshTokenData, null, 2) : "Loading..."}
        </pre>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Console Output */}
      <Box>
        <Typography variant="h6">Console Output</Typography>
        <pre style={{
          marginTop: '1rem',
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          borderRadius: '4px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}>
          {consoleOutput || "Loading..."}
        </pre>
      </Box>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setNotification(prev => ({ ...prev, open: false }))}
          severity={notification.severity}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AuthCheckPage;