import React, { useState, useEffect } from 'react';
import { 
  Paper, Typography, Box, Divider, Button, Snackbar, Alert, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow 
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerIcon from '@mui/icons-material/Timer';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../services/api';

const ACCESS_TOKEN_EXPIRATION_MINUTES = 2;
const REFRESH_TOKEN_EXPIRATION_DAYS = 7;

const CheckCookie = () => {
  const [cookieStatus, setCookieStatus] = useState('Checking...');
  const [responseData, setResponseData] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const { user } = useAuth();

  // Fetch cookie and token information
  const checkHttpOnlyCookie = async () => {
    try {
      const response = await axiosInstance.get('/api/auth/check-cookie', { withCredentials: true });
      setResponseData(response.data);
      setCookieStatus(response.data.authenticated ? 'HTTP-only cookie detected. You are authenticated.' : 'HTTP-only cookie not detected or invalid. You are not authenticated.');
    } catch (error) {
      setCookieStatus(`Error checking authentication: ${error.message}`);
    }
  };

  // Use effect to fetch data on component mount
  useEffect(() => {
    checkHttpOnlyCookie();
  }, []);

  const handleRefresh = async () => {
    try {
      const response = await axiosInstance.post('/api/auth/refresh-token', {}, { withCredentials: true });
      setResponseData(response.data);
      setSnackbar({ open: true, message: 'Tokens refreshed successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to refresh tokens', severity: 'error' });
    }
  };

  const handleCopyAll = (data) => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    setSnackbar({ open: true, message: 'Full response copied to clipboard', severity: 'success' });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Session expiration not set';
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">{cookieStatus}</Typography>
      </Box>
      
      {/* Full Response Section */}
      {responseData && (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ p: 2 }}>Full Response</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Key</strong></TableCell>
                  <TableCell><strong>Value</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(responseData).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">{key}</TableCell>
                    <TableCell sx={{ overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Copy Full Response Button */}
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCopyAll(responseData)}
            sx={{ mt: 2 }}
          >
            Copy Full Response
          </Button>


        </>
      )}
      
      {/* User Info Section */}
      {user && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">
            <AccessTimeIcon fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
            Last login: {formatDate(user.lastLogin)}
          </Typography>

          {/* Display Session Expiration */}
          {responseData?.accessTokenExpiration ? (
            <Typography variant="body2">
              <TimerIcon fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
              Session expires: {formatDate(responseData.accessTokenExpiration)}
            </Typography>
          ) : (
            <Typography variant="body2">
              Session expiration not available
            </Typography>
          )}
        </Box>
      )}

      {/* Refresh Tokens Button */}
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
        sx={{ mt: 2 }}
      >
        Refresh Tokens
      </Button>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default CheckCookie;
