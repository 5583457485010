import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button, Drawer, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../contexts/AuthContext.js'; // Correct import path

const Header = ({ toggleSidebar, selectedChannel }) => {
  const { user, logout } = useAuth(); // Correct usage
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // Default to false
  const isMobile = useMediaQuery('(max-width: 900px)');

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  // Function to remove cookies and handle logout
  const handleLogout = () => {
    // Clear access token from localStorage
    localStorage.removeItem('accessToken');

    // Clear refresh token by setting an expired cookie
    document.cookie = 'refreshToken=; path=/; max-age=0; secure; HttpOnly; SameSite=Strict';

    // Redirect to login page
    window.location.href = '/login';
  };

  const handleMenuItemClick = () => {
    setMobileMenuOpen(false);
  };

  // Automatically close the mobile menu on screen size changes
  useEffect(() => {
    if (!isMobile) {
      setMobileMenuOpen(false); // Close the menu when switching to desktop
    }
  }, [isMobile]);

  // Common navigation items (shared for both desktop and mobile)
  const navItems = user ? (
    <>
      <NavLink to="/dashboard" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>Dashboard</Button>
      </NavLink>
      <NavLink to="/profile" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>Profile</Button>
      </NavLink>
      <NavLink to="/userchannels" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>User Channels</Button>
      </NavLink>
      <NavLink to="/authcheck" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>Auth Check</Button>
      </NavLink>
      <NavLink to="/TokenTester" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>TokenTester</Button>
      </NavLink>
      <NavLink to="/Generate" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>TokenTesterDebug</Button>
      </NavLink>
      <Button color="inherit" onClick={handleLogout} fullWidth>
        Logout
      </Button>
    </>
  ) : (
    <>
      <NavLink to="/login" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>Login</Button>
      </NavLink>
      <NavLink to="/register" style={{ textDecoration: 'none' }} onClick={handleMenuItemClick}>
        <Button color="inherit" fullWidth>Register</Button>
      </NavLink>
    </>
  );

  return (
    <>
      <Box
        sx={{
          color: 'black',
          padding: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          borderBottom: '1px solid',
          borderColor: 'grey.100',
        }}
      >
        {/* Logo clickable area for sidebar toggle */}
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={toggleSidebar}
        >
          <img src="/images/logo.png" alt="Logo" style={{ width: 40, marginRight: 8 }} />
          <Typography variant="h6"></Typography>
        </Box>

        {/* Render desktop or mobile navigation based on isMobile */}
        {!isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Desktop Navigation - Items aligned side by side */}
            {navItems}
          </Box>
        ) : (
          <IconButton color="inherit" onClick={toggleMobileMenu}>
            <MenuIcon />
          </IconButton>
        )}
      </Box>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{
          '& .MuiDrawer-paper': {
            width: '250px',
            padding: 2,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {navItems}
        </Box>
      </Drawer>

      {/* Display Channel Header only if selectedChannel is available */}
      {selectedChannel && (
        <Box
          sx={{
            position: 'absolute',
            top: 5,
            left: isMobile ? 50 : 260,
            right: isMobile ? 50 : 600,
            zIndex: 1000,
            padding: '10px',
          }}
        >
          <Typography variant="h6" onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
            {`Channel: ${selectedChannel.name}`}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Header;