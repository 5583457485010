import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext.js';  // Import the useAuth hook

const ProfilePage = () => {
  const { user, loading, error, fetchUserData } = useAuth();  // Destructure values from AuthContext

  // Optionally, you can re-fetch user data when the component mounts if it's not already available.
  useEffect(() => {
    if (!user && !loading && !error) {
      const token = localStorage.getItem('accessToken') || getCookie('accessToken');
      if (token) {
        fetchUserData(token);
      }
    }
  }, [user, loading, error, fetchUserData]);

  if (loading) return <div>Loading...</div>;  // Show loading message while fetching data
  if (error) return <div>Error: {error}</div>;  // Show any error message

  return (
    <div>
      {user ? (
        <div>
          <h2>Profile Page</h2>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Username:</strong> {user.username || 'Not set'}</p>
          <p><strong>Last Active Channel:</strong> {user.lastActiveChannel || 'N/A'}</p>
          <p><strong>Channels:</strong> {user.channels.length > 0 ? user.channels.join(', ') : 'No channels'}</p>
          <div>
            <h4>Last Interacted Messages</h4>
            {Object.keys(user.lastInteractedMessages).length > 0 ? (
              Object.entries(user.lastInteractedMessages).map(([channelId, message]) => (
                <div key={channelId}>
                  <p><strong>Channel:</strong> {channelId}</p>
                  <p><strong>Last Message:</strong> {message}</p>
                </div>
              ))
            ) : (
              <p>No messages found</p>
            )}
          </div>
        </div>
      ) : (
        <div>No user data found</div>
      )}
    </div>
  );
};

export default ProfilePage;
