import React from 'react';
import { Box, Typography } from '@mui/material';
import { InsertDriveFile, PictureAsPdf, Description, AudioFile, VideoFile } from '@mui/icons-material';

const FilePreviewItem = ({ fileUrl }) => {
    // Clean up the URL and extract filename
    const cleanUrl = decodeURIComponent(fileUrl);
    const fileName = cleanUrl.split('/').pop().split('?')[0];
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // File type detection using decoded URL
    const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
    const isVideo = /\.(mp4|mov|webm|avi|mkv)$/i.test(fileName);
    const isAudio = /\.(mp3|wav|ogg|m4a)$/i.test(fileName);
    const isPdf = /\.pdf$/i.test(fileName);

    // console.log('File Type Detection:', {
    //     fileName,
    //     fileExtension,
    //     isImage,
    //     isVideo,
    //     isAudio,
    //     isPdf
    // });

    // For images
    if (isImage) {
        return (
            <Box sx={{ textAlign: 'left' }}>
                <img
                    src={fileUrl}
                    alt={fileName}
                    loading="lazy"
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '4px'
                    }}
                />
                <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                    {fileName}
                </Typography>
            </Box>
        );
    }

    // For videos
    if (isVideo) {
        return (
            <Box sx={{ textAlign: 'left' }}>
                <video
                    controls
                    preload="metadata"
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '4px'
                    }}
                >
                    <source src={fileUrl} type={`video/${fileExtension}`} />
                    Your browser does not support video playback.
                </video>
                <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                    {fileName}
                </Typography>
            </Box>
        );
    }

    // For audio
    if (isAudio) {
        return (
            <Box sx={{ textAlign: 'left' }}>
                <audio
                    controls
                    preload="metadata"
                    style={{ width: '100%' }}
                >
                    <source src={fileUrl} type={`audio/${fileExtension}`} />
                    Your browser does not support audio playback.
                </audio>
                <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                    {fileName}
                </Typography>
            </Box>
        );
    }

    // For pdfs and other files
    return (
        <Box sx={{ 
            padding: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            border: '1px solid #ddd'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}>
                {isPdf ? (
                    <PictureAsPdf sx={{ fontSize: 40, color: '#F40F02' }} />
                ) : (
                    <InsertDriveFile sx={{ fontSize: 40, color: '#6E6E6E' }} />
                )}
                <Box>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {fileName}
                    </Typography>
                    <a 
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: '#1976d2',
                            textDecoration: 'none',
                            fontSize: '0.875rem'
                        }}
                    >
                        Download
                    </a>
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(FilePreviewItem);