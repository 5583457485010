// AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api';

const AuthContext = createContext(null);

// Constants
const TOKEN_CHECK_INTERVAL = 10000; // 10 seconds
const TOKEN_REFRESH_THRESHOLD = 30000; // 30 seconds before expiration

// Helper function to get stored values
const getStoredValue = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error reading ${key} from storage:`, error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const checkIntervalRef = useRef(null);
  
  const [state, setState] = useState({
    user: getStoredValue('user'),
    isAuthenticated: false,
    loading: true,
    accessToken: localStorage.getItem('accessToken'),
    accessTokenExpiration: getStoredValue('accessTokenExpiration'),
    refreshTokenExpiration: getStoredValue('refreshTokenExpiration'),
    error: null,
    isRefreshing: false
  });

  const updateAuthState = useCallback((updates) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  const handleSessionExpired = useCallback(() => {
    localStorage.clear();
    axiosInstance.defaults.headers.common['Authorization'] = '';
    
    updateAuthState({
      user: null,
      isAuthenticated: false,
      loading: false,
      accessToken: null,
      accessTokenExpiration: null,
      refreshTokenExpiration: null,
      error: 'Session expired. Please log in again.',
      isRefreshing: false
    });
    
    navigate('/login', { replace: true });
  }, [navigate, updateAuthState]);

  const refreshTokens = useCallback(async () => {
    if (state.isRefreshing) {
      console.log('Token refresh already in progress');
      return false;
    }
  
    try {
      updateAuthState({ isRefreshing: true });
      console.log('Attempting to refresh tokens');
  
      // Refresh token is sent via cookies (withCredentials: true)
      const response = await axiosInstance.post('/api/auth/refresh-token', null, {
        withCredentials: true
      });
  
      const { accessToken, accessTokenExpiration } = response.data;
  
      // Update storage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
  
      // Update axios
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  
      // Update state
      updateAuthState({
        accessToken,
        accessTokenExpiration,
        isAuthenticated: true,
        isRefreshing: false,
        error: null
      });
  
      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      if (error.response?.status === 401) {
        handleSessionExpired();
      }
      return false;
    } finally {
      updateAuthState({ isRefreshing: false });
    }
  }, [state.isRefreshing, updateAuthState, handleSessionExpired]);

  const generateTokens = useCallback(async () => {
    const userId = state.user?._id;
    if (!userId || !state.accessToken) {
      console.error('Cannot generate tokens: missing user ID or access token');
      return false;
    }

    try {
      console.log('Generating new tokens for user:', userId);

      const response = await axiosInstance.get('/api/auth/generate-tokens', {
        params: { userId },
        headers: { 'Authorization': `Bearer ${state.accessToken}` },
        withCredentials: true
      });

      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      // Update storage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      if (user) localStorage.setItem('user', JSON.stringify(user));

      // Update axios
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Update state
      updateAuthState({
        user: user || state.user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        error: null
      });

      return true;
    } catch (error) {
      console.error('Token generation failed:', error);
      if (error.response?.status === 401) {
        handleSessionExpired();
      }
      return false;
    }
  }, [state.user, state.accessToken, updateAuthState, handleSessionExpired]);

  // Token check effect
  useEffect(() => {
    const setupTokenCheck = () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }

      if (!state.accessTokenExpiration || !state.isAuthenticated || state.isRefreshing) {
        return;
      }

      const checkTokenExpiration = async () => {
        const timeUntilExpiry = state.accessTokenExpiration - Date.now();
        
        if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD && !state.isRefreshing) {
          console.log('Token expiring soon, refreshing...');
          await refreshTokens();
        }
      };

      checkIntervalRef.current = setInterval(checkTokenExpiration, TOKEN_CHECK_INTERVAL);
    };

    setupTokenCheck();

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [state.accessTokenExpiration, state.isAuthenticated, state.isRefreshing, refreshTokens]);

  // Add axios interceptor for token updates
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosInstance.interceptors.request.eject(interceptor);
    };
  }, []);

  // Initialize auth state
// Initialize auth state
useEffect(() => {
  const validateSession = async () => {
    const storedToken = localStorage.getItem('accessToken');
    const storedExpiration = getStoredValue('accessTokenExpiration');

    if (!storedToken || !storedExpiration) {
      updateAuthState({ loading: false });
      return;
    }

    // Ensure axios has the token
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

    try {
      // First check if token is expired
      if (storedExpiration <= Date.now()) {
        console.log('Stored token expired, attempting refresh');
        const refreshSuccess = await refreshTokens();
        if (!refreshSuccess) {
          throw new Error('Token refresh failed');
        }
        return;
      }

      // If token is valid, get user data
      const response = await axiosInstance.get('/api/users/me');
      
      if (!response.data?._id) {
        throw new Error('Invalid user data');
      }

      updateAuthState({
        user: response.data,
        accessToken: storedToken,
        accessTokenExpiration: storedExpiration,
        isAuthenticated: true,
        loading: false,
        error: null
      });
    } catch (error) {
      console.error('Session validation failed:', error);
      handleSessionExpired();
    }
  };

  validateSession();
}, []); // Run only on mount

// Add axios interceptor for token updates
useEffect(() => {
  const interceptor = axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return () => {
    axiosInstance.interceptors.request.eject(interceptor);
  };
}, []);

  const login = async (email, password) => {
    try {
      updateAuthState({ loading: true, error: null });
      const response = await axiosInstance.post('/api/users/login', { email, password });
      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      // Update storage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      localStorage.setItem('user', JSON.stringify(user));

      // Update axios
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Update state
      updateAuthState({
        user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        loading: false,
        error: null
      });

      return true;
    } catch (error) {
      updateAuthState({
        loading: false,
        error: error.response?.data?.message || 'Login failed',
        isAuthenticated: false
      });
      return false;
    }
  };

  const logout = useCallback(async () => {
    try {
      await axiosInstance.post('/api/auth/logout', null, { withCredentials: true });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.clear();
      axiosInstance.defaults.headers.common['Authorization'] = '';
      updateAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,
        error: null,
        isRefreshing: false
      });
      navigate('/login', { replace: true });
    }
  }, [navigate, updateAuthState]);

  const value = {
    ...state,
    login,
    logout,
    refreshTokens,
    generateTokens
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;