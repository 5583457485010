import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api.js'; // Updated import


const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [disableForm, setDisableForm] = useState(false);
    const [error, setError] = useState(null);  // Store error messages
    const [message, setMessage] = useState('');  // Store success or info messages

    useEffect(() => {
        setError(null); // Clear error messages when form fields change
    }, [email, password, confirmPassword]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setDisableForm(true);
        setMessage('');

        try {
            const response = await axiosInstance.post('api/users/register', { email, password });
            const data = response.data;

            console.log('Registration successful:', data);

            setMessage('Registration successful! Redirecting to login...');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (err) {
            console.error('Registration error:', err);

            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('Registration failed. Please try again.');
            }

            setDisableForm(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(90vh - 60px)', // Subtract header height
                padding: 2,
            }}
        >
            <Box sx={{ maxWidth: 400, width: '100%' }}>
                <Typography variant="h4" gutterBottom align="center">
                    Register
                </Typography>

                {error && (
                    <Typography color="error" variant="body2" align="center" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}

                {message && (
                    <Typography
                        color="success.main"
                        variant="body2"
                        align="center"
                        sx={{ mb: 2 }}
                    >
                        {message}
                    </Typography>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={disableForm}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={disableForm}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        disabled={disableForm}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={disableForm}
                    >
                        {disableForm ? 'Registering...' : 'Register'}
                    </Button>
                </form>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography variant="body2">Already have an account?</Typography>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{ marginLeft: 1 }}
                        onClick={() => navigate('/login')}
                        disabled={disableForm}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Register;
