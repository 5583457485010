import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Alert, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js'; 


const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loading: authLoading, login } = useAuth();

  const [formData, setFormData] = useState({ 
    email: '', 
    password: '' 
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [healthData, setHealthData] = useState({
    status: null,
    serverInfo: null,
    errorDetails: null
  });

  // Health check with proper error handling
  const checkApiHealth = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/api/health');
      
      setHealthData({
        title: response.data.title, // Add title from the response
        status: response.data.status,
        serverInfo: response.data.serverInfo,
        errorDetails: null
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message 
        || error.message 
        || 'Unable to connect to the API service';
        
      setHealthData({
        status: 'error',
        serverInfo: null,
        errorDetails: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, authLoading, navigate]);

  useEffect(() => {
    checkApiHealth();
  }, []);

  const validateForm = () => {
    setError(null);
    if (!formData.email || !formData.password) {
      setError('Email and password are required');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setError('Please enter a valid email address');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      const success = await login(formData.email, formData.password);
      if (success) navigate('/dashboard');
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed. Please check your credentials');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    if (error) setError(null);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '70vh',
      p: 3,
      mt: '60px'
    }}>
      <Box sx={{ maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </form>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button onClick={() => navigate('/register')}>
            Create New Account
          </Button>
        </Box>

        {/* Health Status Panel */}
        <Box sx={{ 
          mt: 4,
          p: 2,
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          textAlign: 'left'
        }}>
          
          {loading ? (
            <Typography variant="body2">Checking system health...</Typography>
          ) : healthData.errorDetails ? (
            <Alert severity="error">
              <Typography variant="body2">
                API Connection Error: {healthData.errorDetails}
              </Typography>
            </Alert>
          ) : (
            <>
              <Typography variant="body2">
                server : {healthData.title || 'Unknown'}
              </Typography>
              <Typography variant="body2">
                status  : {healthData.status || 'Unknown'}
              </Typography>
              {healthData.serverInfo && (
                <>
                  <Typography variant="body2">
                    Environment: {healthData.serverInfo.nodeEnv}
                  </Typography>
                  <Typography variant="body2">
                    Uptime: {healthData.serverInfo.uptime?.toFixed(2) || 0} seconds
                  </Typography>
                  <Typography variant="body2">
                    Memory Usage: {Math.round(
                      (healthData.serverInfo.memory?.heapUsed || 0) / 1024 / 1024
                    )} MB
                  </Typography>
                  <Typography variant="body2">
                    Last Checked: {new Date().toLocaleTimeString()}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;