import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.js'; // Add .js extension


// Create the root element for the app
const root = ReactDOM.createRoot(document.getElementById('root'));
// Render the app with Router and ErrorBoundary
root.render(
  // <ErrorBoundary>
    <Router>
      <App />
    </Router>
  // </ErrorBoundary>
);