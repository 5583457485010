import React from 'react';
import { Box } from '@mui/material'; // Ensure Box is imported
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <div>
      {/* Header Box */}
      <Box
        sx={{
          paddingTop: 1,  // Adds padding at the top for the pages
          marginTop: '60px', // Optional, if you want more space under the header
          bgcolor:'red',
        }}
      >
        {/* You can add any header content here */}
      </Box>

      <Box

      >
        {/* Render the page content dynamically */}
        <main>
          <Outlet />  {/* This is where the nested routes will be displayed */}
        </main>
      </Box>
    </div>
  );
}

export default Layout;
