import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api.js';

// Create the AuthContext
const AuthContext = createContext(null);

// Constants
const TOKEN_CHECK_INTERVAL = 1000; // Check every second for smoother countdown
const TOKEN_REFRESH_THRESHOLD = 30000; // 30 seconds before expiration

// Helper function to safely get and parse stored values from localStorage
const getStoredValue = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error reading ${key} from storage:`, error);
    return null;
  }
};

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const checkIntervalRef = useRef(null);

  // Initial state with added refresh notification fields
  const [state, setState] = useState({
    user: getStoredValue('user'),
    isAuthenticated: false,
    loading: true,
    accessToken: localStorage.getItem('accessToken'),
    accessTokenExpiration: getStoredValue('accessTokenExpiration'),
    refreshTokenExpiration: getStoredValue('refreshTokenExpiration'),
    error: null,
    isRefreshing: false,
    refreshMessage: null, // For refresh notifications
    timeUntilRefresh: null // For countdown display
  });

  // Function to update the auth state
  const updateAuthState = useCallback((updates) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  // Function to handle session expiration
  const handleSessionExpired = useCallback(() => {
    localStorage.clear();
    axiosInstance.defaults.headers.common['Authorization'] = '';

    updateAuthState({
      user: null,
      isAuthenticated: false,
      loading: false,
      accessToken: null,
      accessTokenExpiration: null,
      refreshTokenExpiration: null,
      error: 'Session expired. Please log in again.',
      isRefreshing: false,
      refreshMessage: 'Session expired. Redirecting to login...',
      timeUntilRefresh: null
    });

    setTimeout(() => {
      navigate('/login', { replace: true });
    }, 2000);
  }, [navigate, updateAuthState]);

  // Function to refresh tokens
  const refreshTokens = useCallback(async () => {
    if (state.isRefreshing) {
      return false;
    }

    try {
      updateAuthState({ 
        isRefreshing: true,
        refreshMessage: 'Refreshing tokens...'
      });

      const response = await axiosInstance.post('/api/auth/refresh-token', null, {
        withCredentials: true
      });

      const { accessToken, accessTokenExpiration } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      updateAuthState({
        accessToken,
        accessTokenExpiration,
        isAuthenticated: true,
        isRefreshing: false,
        error: null,
        refreshMessage: 'Token refresh successful',
        timeUntilRefresh: null
      });

      // Clear success message after delay
      setTimeout(() => {
        updateAuthState({ refreshMessage: null });
      }, 3000);

      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      
      updateAuthState({
        refreshMessage: 'Token refresh failed',
        isRefreshing: false
      });

      if (error.response?.status === 401) {
        handleSessionExpired();
      }
      return false;
    }
  }, [state.isRefreshing, updateAuthState, handleSessionExpired]);

  // Effect to check token expiration periodically
  useEffect(() => {
    const setupTokenCheck = () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }

      if (!state.accessTokenExpiration || !state.isAuthenticated) {
        return;
      }

      const checkTokenExpiration = async () => {
        if (state.isRefreshing) return;

        const timeUntilExpiry = state.accessTokenExpiration - Date.now();
        const timeInSeconds = Math.floor(timeUntilExpiry / 1000);

        if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD && timeUntilExpiry > 0) {
          updateAuthState({
            refreshMessage: `Token will refresh in ${timeInSeconds} seconds`,
            timeUntilRefresh: timeInSeconds
          });

          if (timeInSeconds <= 5) {
            console.log('Token expiring soon, initiating refresh...');
            await refreshTokens();
          }
        } else if (timeUntilExpiry <= 0) {
          updateAuthState({
            refreshMessage: 'Token expired, refreshing now...',
            timeUntilRefresh: 0
          });
          await refreshTokens();
        } else {
          updateAuthState({
            refreshMessage: null,
            timeUntilRefresh: null
          });
        }
      };

      // Initial check
      checkTokenExpiration();
      
      // Set up interval for subsequent checks
      checkIntervalRef.current = setInterval(checkTokenExpiration, TOKEN_CHECK_INTERVAL);
    };

    setupTokenCheck();

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [state.accessTokenExpiration, state.isAuthenticated, state.isRefreshing, refreshTokens, updateAuthState]);

  // Effect to initialize auth state on mount
  useEffect(() => {
    const validateSession = async () => {
      const storedToken = localStorage.getItem('accessToken');
      const storedExpiration = getStoredValue('accessTokenExpiration');

      if (!storedToken || !storedExpiration) {
        updateAuthState({ loading: false });
        return;
      }

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

      try {
        if (storedExpiration <= Date.now()) {
          console.log('Stored token expired, attempting refresh');
          const refreshSuccess = await refreshTokens();
          if (!refreshSuccess) {
            throw new Error('Token refresh failed');
          }
          return;
        }

        const response = await axiosInstance.get('/api/users/me');
        if (!response.data?._id) {
          throw new Error('Invalid user data');
        }

        updateAuthState({
          user: response.data,
          accessToken: storedToken,
          accessTokenExpiration: storedExpiration,
          isAuthenticated: true,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Session validation failed:', error);
        handleSessionExpired();
      }
    };

    validateSession();
  }, [updateAuthState, handleSessionExpired, refreshTokens]);

  // Effect to add axios interceptor for token updates
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshSuccess = await refreshTokens();
            if (refreshSuccess) {
              const token = localStorage.getItem('accessToken');
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            }
          } catch (refreshError) {
            console.error('Token refresh failed during interceptor:', refreshError);
            handleSessionExpired();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [refreshTokens, handleSessionExpired]);

  // Function to handle login
  const login = async (email, password) => {
    try {
      updateAuthState({ loading: true, error: null });

      const response = await axiosInstance.post('/api/users/login', { email, password });
      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      localStorage.setItem('user', JSON.stringify(user));

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      updateAuthState({
        user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        loading: false,
        error: null,
        refreshMessage: 'Login successful'
      });

      setTimeout(() => {
        updateAuthState({ refreshMessage: null });
      }, 3000);

      return true;
    } catch (error) {
      updateAuthState({
        loading: false,
        error: error.response?.data?.message || 'Login failed',
        isAuthenticated: false,
        refreshMessage: 'Login failed'
      });

      setTimeout(() => {
        updateAuthState({ refreshMessage: null });
      }, 3000);

      return false;
    }
  };

  // Function to handle logout
  const logout = useCallback(async () => {
    try {
      updateAuthState({ refreshMessage: 'Logging out...' });
      await axiosInstance.post('/api/auth/logout', null, { withCredentials: true });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.clear();
      axiosInstance.defaults.headers.common['Authorization'] = '';
      updateAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,
        error: null,
        isRefreshing: false,
        refreshMessage: null,
        timeUntilRefresh: null
      });
      navigate('/login', { replace: true });
    }
  }, [navigate, updateAuthState]);

  const value = {
    ...state,
    login,
    logout,
    refreshTokens
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      {state.refreshMessage && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '12px 20px',
            borderRadius: '4px',
            backgroundColor: state.timeUntilRefresh > 5 ? '#4CAF50' : '#FFA726',
            color: 'white',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            zIndex: 9999,
            transition: 'all 0.3s ease'
          }}
        >
          {state.refreshMessage}
        </div>
      )}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;