import axios from 'axios';

const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname.includes('localhost') 
      ? 'http://localhost:5000'
      : 'https://server-vau7t.vercel.app';
  }
  return 'http://localhost:5000';
};

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});



// Add request interceptor for debugging
axiosInstance.interceptors.request.use(
  (config) => {
    console.log('Request cookies:', document.cookie);
    return config;
  },
  (error) => Promise.reject(error)
);



// Add initial token if available
const token = localStorage.getItem('accessToken');
if (token) {
  console.log('Setting Authorization header with token:', token);
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Add response interceptor to handle auth errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 403) {
      localStorage.removeItem('accessToken');
      delete axiosInstance.defaults.headers.common['Authorization'];
      console.warn('Access token expired or invalid. Removed token from localStorage and Axios headers.');
    }

    console.error('Axios error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;