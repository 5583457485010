import React from 'react';
import { Box, ButtonBase, Typography, CardContent, IconButton, Popover, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserChannels from '../components/UserChannels.js'; // Import UserChannels

const Sidebar = ({ selectedChannel, onChannelSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentChannel, setCurrentChannel] = React.useState(null);

  const handleClickPopover = (event, channel) => {
    setAnchorEl(event.currentTarget);
    setCurrentChannel(channel);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setCurrentChannel(null);
  };

  const handleEditChannel = () => {
    // Logic to edit the channel
    handleClosePopover();
  };

  const handleDeleteChannel = () => {
    // Logic to delete the channel
    handleClosePopover();
  };

  return (
    <Box sx={{ pt: 5, bgcolor: '' }}>
      <UserChannels onChannelSelect={onChannelSelect} selectedChannel={selectedChannel} /> {/* Render UserChannels component */}
      
      <Popover
        open={Boolean(anchorEl) && currentChannel}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEditChannel}>Edit</MenuItem>
        {currentChannel && !currentChannel.isDefault && (
          <MenuItem onClick={handleDeleteChannel}>Delete</MenuItem>
        )}
      </Popover>
    </Box>
  );
};

export default Sidebar;