import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';
import {
    CircularProgress, Box, Snackbar, Typography, IconButton, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';

import ChatDisplay from './ChatDisplay.js';
import ChatInput from './ChatInput.js';
import FilesInChannel from './FilesInChannel.js'; // Import FilesInChannel


const LAST_MESSAGE_KEY = 'lastSentMessage'; // NEW: Key for last sent message


const Chat = ({ channel, isSidebarOpen, sidebarWidth }) => {
    const [messages, setMessages] = useState([]);
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const { user, loading: authLoading } = useAuth();
    const [isMinimized, setIsMinimized] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [deletingMessageId, setDeletingMessageId] = useState(null);
    const messagesEndRef = useRef(null);

        // Simulate UploadQueue context or external function
        const handleAddFileFromChat = (file) => {
            // This is where you'd handle adding the file to your UploadQueue context or state.
            console.log('File added from chat:', file);
            // Optionally trigger a re-render or notify the UploadQueue to reopen.
        };


    const fetchMessages = useCallback(async () => {
        if (!channel?._id) {
            console.error('Channel ID is missing or invalid');
            setError('Channel ID is missing or invalid');
            return;
        }
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/api/message/channel/${channel._id}/messages`);
            if (response.data.success) {
                setMessages(response.data.messages);
            } else {
                setError('Failed to fetch messages: ' + response.data.message);
            }
        } catch (error) {
            setError('Error fetching messages: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    }, [channel]);

    useEffect(() => {
        if (channel) {
            fetchMessages().then(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            });
        }
    }, [channel, fetchMessages]);

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleMessageSent = async () => {
        // await fetchObjects();
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDeleteMessage = async (messageId) => {        // Check if messageId is a valid MongoDB ObjectId
        const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(messageId);
        
        if (!messageId || !channel?._id) {
            console.error('Missing message or channel ID');
            setError('Invalid message or channel ID');
            return;
        }
    
        if (!isValidObjectId) {
            console.error('Invalid ObjectId format:', messageId);
            setError('Invalid message format');
            return;
        }

    try {
        console.log('Attempting to delete message:', {
            messageId,
            channelId: channel._id,
            url: `/api/message/channel/${channel._id}/message/${messageId}`
        });
    
            const response = await axiosInstance.delete(
                `/api/message/channel/${channel._id}/message/${messageId}`
            );
            
            console.log('Delete response:', response.data);
    
            if (response.data.success) {
                if (response.data.filesToDelete && response.data.filesToDelete.length > 0) {
                    // If there are files to delete, show confirmation dialog
                    setFilesToDelete(response.data.filesToDelete);
                    setDeletingMessageId(messageId);
                    setConfirmDialogOpen(true);
                } else {
                    // If no files, message is already deleted on the backend
                    // Just update the UI
                    setMessages((prevMessages) => 
                        prevMessages.filter((message) => message._id !== messageId)
                    );
                    await fetchObjects(); // Refresh file list
                }
            }
        } catch (error) {
            console.error('Delete error details:', {
                error,
                response: error.response,
                messageId,
                channelId: channel._id
            });
            
            setError(
                'Error deleting message: ' + 
                (error.response?.data?.message || error.message || 'Unknown error')
            );
        }
    };




    const handleConfirmDelete = async () => {
        try {
            if (!deletingMessageId || !channel?._id) return;
    
            const response = await axiosInstance.post(
                `/api/message/channel/${channel._id}/message/${deletingMessageId}/confirmDelete`,
                { filesToDelete }
            );
    
            if (response.data.success) {
                // Remove the message from the UI
                setMessages((prevMessages) => 
                    prevMessages.filter((message) => message._id !== deletingMessageId)
                );
                
                // Clear the deletion state
                setConfirmDialogOpen(false);
                setFilesToDelete([]);
                setDeletingMessageId(null);
                
                // Refresh the file list
                // await fetchObjects();
            } else {
                setError('Failed to delete message: ' + response.data.message);
            }
        } catch (error) {
            setError('Error confirming deletion: ' + (error.response?.data?.message || error.message));
        }
    };


    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
        setFilesToDelete([]);
        setDeletingMessageId(null);
    };

    if (authLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!user) {
        return <Box>Please log in to view this content</Box>;
    }

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    // const nonEmptyFiles = objects.filter(file => file.Size > 0);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >

<FilesInChannel channel={channel} /> {/* Render FilesInChannel */}



            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                <ChatDisplay
                    messages={messages}
                    loading={loading}
                    onDeleteMessage={(messageId) => {
                        // Make sure we're using the correct _id from the message object
                        const message = messages.find(
                            (m) => m._id === messageId || m.message_id === messageId
                        );
                        if (message) {
                            console.log('Deleting message:', message);
                            // Always use the _id property for deletion
                            handleDeleteMessage(message._id || message.message_id);
                        } else {
                            console.error('Message not found:', messageId);
                            setError('Invalid message ID format');
                        }
                    }}
                    messagesEndRef={messagesEndRef}
                />
                <div ref={messagesEndRef} />
            </Box>
    
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: isSidebarOpen ? `${sidebarWidth}px` : '0',
                    width: isSidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
                    backgroundColor: '#fff',
                    borderTop: '1px solid #ddd',
                    zIndex: 10,
                    transition: 'left 0.3s ease, width 0.3s ease',
                }}
            >
                <ChatInput
                    user={user}
                    currentChannel={channel}
                    setMessages={setMessages}
                    axiosInstance={axiosInstance}
                    setSnackbarOpen={setSnackbarOpen}
                    onMessageSent={handleMessageSent}
                    onFileAdded={handleAddFileFromChat}
                />
            </Box>
    
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Uploaded Files"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
    
            <Dialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this message and the following files?
                    </DialogContentText>
                    <Box sx={{ mt: 2, maxHeight: '200px', overflowY: 'auto' }}>
                        {filesToDelete?.map((file, index) => (
                            <Box
                                key={index}
                                sx={{
                                    p: 1,
                                    mb: 1,
                                    bgcolor: 'grey.100',
                                    borderRadius: 1,
                                    fontSize: '0.875rem',
                                }}
                            >
                                <Typography variant="subtitle2" component="div">
                                    File {index + 1}:
                                </Typography>
                                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                    Path: {file.filePath}
                                </Typography>
                                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                    URL: {file.fileUrl}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
    
};

export default Chat;
