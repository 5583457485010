import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const baseURL = isDevelopment 
  ? 'http://localhost:5000' 
  : 'https://server-vau7t.vercel.app';

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Add initial token if available
const token = localStorage.getItem('accessToken');
if (token) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Add response interceptor to handle auth errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 403) {
      // Clear invalid token
      localStorage.removeItem('accessToken');
      axiosInstance.defaults.headers.common['Authorization'] = '';
    }
    return Promise.reject(error);
  }
);



export default axiosInstance;