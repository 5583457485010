import React, { useState, useEffect, useCallback } from 'react';
import {
    CircularProgress,
    Box,
    Typography,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles/index.js';

import axiosInstance from '../services/api.js';

const FilesInChannel = ({ channel }) => {
    const [objects, setObjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isMinimized, setIsMinimized] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile devices

    const togglePanel = () => {
        setIsMinimized(!isMinimized);
    };

    const generateFileUrl = (key) => {
        const baseUrl = process.env.REACT_APP_STORJ_DOWNLOAD_BASE_URL;
        if (!baseUrl) {
            console.error("REACT_APP_STORJ_DOWNLOAD_BASE_URL is not defined in the .env file");
            return '';
        }
        return `${baseUrl}/${encodeURIComponent(key)}?wrap=0`;
    };

    const fetchObjects = useCallback(async () => {
        if (!channel?._id) return;

        try {
            setLoading(true);
            const response = await axiosInstance.get(`/api/files/list/${channel._id}`);
            if (response?.data?.success && Array.isArray(response.data.files)) {
                const filesWithUrls = response.data.files
                    .filter(file => file.Size > 0)
                    .map(file => ({
                        ...file,
                        url: generateFileUrl(file.Key),
                    }));
                setObjects(filesWithUrls);
            } else {
                setError('Failed to load files.');
                setObjects([]);
            }
        } catch (err) {
            setError('Error fetching files: ' + (err.response?.data?.message || err.message));
            setObjects([]);
        } finally {
            setLoading(false);
        }
    }, [channel]);

    useEffect(() => {
        if (channel) {
            fetchObjects();
        }
    }, [channel, fetchObjects]);

    return (
        <Box
            sx={{
                width: '100%',
                padding: 0,
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd',
                overflowY: isMinimized ? 'hidden' : 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '95%',
                    cursor: 'pointer',
                }}
                onClick={togglePanel}
            >
                <Typography variant="h6" sx={{ fontSize: '16px', display: isMinimized ? 'none' : 'block' }}>
                    Files in Channel
                </Typography>
                <IconButton size="small">
                    <InsertDriveFile />
                </IconButton>
            </Box>

            {!isMinimized && (
                <Box sx={{ marginTop: 2, width: '100%' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : objects.length > 0 ? (
                        <Box
                            sx={{
                                display: 'grid',
                                gridGap: '10px',
                                gridTemplateColumns: {
                                    xs: 'repeat(auto-fill, minmax(50px, 1fr))',
                                    sm: 'repeat(auto-fill, minmax(80px, 1fr))',
                                    md: 'repeat(auto-fill, minmax(100px, 1fr))',
                                },
                            }}
                        >
                            {objects.map((object, index) => (
                                <Box key={index} sx={{ textAlign: 'left' }}>
                                    {/\.(jpg|jpeg|png|gif)$/i.test(object.Key) ? (
                                        <img
                                            src={object.url}
                                            alt=""
                                            loading="lazy"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '4px',
                                            }}
                                        />
                                    ) : /\.(mp4|mov|webm)$/i.test(object.Key) ? (
                                        <video
                                            src={object.url}
                                            controls
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '4px',
                                            }}
                                        />
                                    ) : (
                                        <a
                                            href={object.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                display: 'inline-block',
                                                width: '100%',
                                                height: 'auto',
                                                lineHeight: '50px',
                                                textAlign: 'center',
                                                border: '1px solid #ddd',
                                                borderRadius: '4px',
                                                textDecoration: 'none',
                                            }}
                                        >
                                            View File
                                        </a>
                                    )}
                                    {!isMobile && ( // Hide metadata on mobile
                                        <Box
                                            sx={{
                                                mt: 1,
                                                textAlign: 'left',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {object.Key.split('/').pop()}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Last Modified: {new Date(object.LastModified).toLocaleString()}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '12px',
                                                }}
                                            >
                                                Size: {object.Size} bytes
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography>No files available.</Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default FilesInChannel;
