import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { AuthProvider } from './contexts/AuthContext';
import { UploadQueueProvider } from './contexts/UploadQueueContext';

import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import TestPage from './pages/TestPage';
import LoginPage from './pages/LoginPage';
import Register from './pages/RegisterPage';
import AuthCheckPage from './pages/AuthCheckPage';
import ProfilePage from './pages/ProfilePage';
import UserChannels from './components/UserChannels';
import NotFound from './components/NotFound';
import Header from './components/Header';
import UploadQueue from './components/UploadQueue';
import theme from './theme';
import ProtectedRoute from './components/ProtectedRoute';
// import YourComponent from './components/YourComponent'; // Ensure correct import
import CheckCookie from './pages/CheckCookie';
import TokenTester from './pages/TokenTester';
import TokenGeneratorTest from './pages/TokenGeneratorTest';



const App = () => {
  const [testMode, setTestMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getAllEnvVariables = () => {
    let envVars = '';
    for (const [key, value] of Object.entries(process.env)) {
        let sanitizedValue = value;
        if (key.includes('SECRET') || key.includes('PASSWORD')) {
            sanitizedValue = '[Sanitized]';
        }
        // Log only variables with REACT_APP_ prefix
        if (key.startsWith('REACT_APP_')) {
            envVars += `${key}: ${sanitizedValue}\n`;
        }
    }
    return envVars;
};


  useEffect(() => {
    const envMessage = getAllEnvVariables();
    // console.log(envMessage);
  }, []);

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenSnackbar(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <UploadQueueProvider>
          <Header />
          <UploadQueue />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/" element={<Layout />}>
              <Route path="/login" element={<LoginPage setErrorMessage={handleError} setOpenSnackbar={setOpenSnackbar} />} />
              <Route path="/register" element={<Register />} />
              <Route path="/authcheck" element={<AuthCheckPage />} />
              <Route path="/test" element={<ProtectedRoute testMode={testMode}><TestPage /></ProtectedRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute testMode={testMode}><Dashboard /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute testMode={testMode}><ProfilePage /></ProtectedRoute>} />
              <Route path="/checkcookie" element={<ProtectedRoute testMode={testMode}><CheckCookie /></ProtectedRoute>} />
              <Route path="/tokentester" element={<ProtectedRoute testMode={testMode}><TokenTester /></ProtectedRoute>} />
              <Route path="/generate" element={<ProtectedRoute testMode={testMode}><TokenGeneratorTest /></ProtectedRoute>} />


              <Route path="/userchannels" element={<ProtectedRoute testMode={testMode}><UserChannels /></ProtectedRoute>} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* <YourComponent /> Add YourComponent here */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={9000}
            onClose={() => setOpenSnackbar(false)}
            message={errorMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          />
        </UploadQueueProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;