import axios from 'axios';

// Determine the base URL based on the environment
const isDevelopment = process.env.NODE_ENV === 'development';
const baseURL = isDevelopment 
  ? 'http://localhost:5000' 
  : 'https://server-vau7t.vercel.app';

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL,
  timeout: 10000, // 10 seconds timeout
  withCredentials: true, // Enable cookies for cross-origin requests
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Add initial token if available
const token = localStorage.getItem('accessToken');
if (token) {
  console.log('Setting Authorization header with token:', token); // Debugging
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Add response interceptor to handle auth errors
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  async (error) => {
    // Handle errors
    if (error.response?.status === 403) {
      // Clear invalid token
      localStorage.removeItem('accessToken');
      delete axiosInstance.defaults.headers.common['Authorization']; // Remove the Authorization header
      console.warn('Access token expired or invalid. Removed token from localStorage and Axios headers.');
    }

    // Log the error for debugging
    console.error('Axios error:', error);

    // Reject the error to propagate it to the calling function
    return Promise.reject(error);
  }
);

export default axiosInstance;