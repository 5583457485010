import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
// import ErrorBoundary from './ErrorBoundary.js';

// Suppress ResizeObserver errors
// if (window.ResizeObserver) {
//   const resizeObserverErrDiv = document.createElement('div');
//   resizeObserverErrDiv.id = 'resize-observer-error';
//   resizeObserverErrDiv.style.display = 'none';
//   document.body.appendChild(resizeObserverErrDiv);

//   const RO = window.ResizeObserver;
//   window.ResizeObserver = function(callback) {
//     const ro = new RO((entries, observer) => {
//       try {
//         callback(entries, observer);
//       } catch (err) {
//         const errorDiv = document.getElementById('resize-observer-error');
//         if (errorDiv) {
//           errorDiv.innerHTML += 'ResizeObserver loop completed with undelivered notifications.<br>';
//         }
//       }
//     });
//     return ro;
//   };
// }

// Create the root element for the app
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app with Router and ErrorBoundary
root.render(
  // <ErrorBoundary>
    <Router>
      <App />
    </Router>
  // </ErrorBoundary>
);