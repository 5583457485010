import React, { useEffect, useCallback } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { Close, ArrowBack, ArrowForward } from '@mui/icons-material';

const FilePreviewModal = ({ open = false, onClose, files = [], currentIndex = 0, onNext, onPrev }) => {
  // Safety check for required props and array validity
  if (!open || !Array.isArray(files) || files.length === 0) {
    return null;
  }

  // Safety check for current file
  const currentFile = files[currentIndex];
  if (!currentFile) {
    return null;
  }

  const isLastFile = currentIndex === files.length - 1;
  const isFirstFile = currentIndex === 0;

  // Handle keyboard navigation
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowRight' && !isLastFile) {
      onNext?.();
    } else if (e.key === 'ArrowLeft' && !isFirstFile) {
      onPrev?.();
    } else if (e.key === 'Escape') {
      onClose?.();
    }
  }, [isLastFile, isFirstFile, onNext, onPrev, onClose]);

  // Add and remove keyboard event listener
  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [open, handleKeyDown]);

  const handleCenterClick = (e) => {
    e.stopPropagation();
    if (isLastFile) {
      onClose?.();
    } else {
      onNext?.();
    }
  };

  const handlePrevClick = (e) => {
    e.stopPropagation();
    onPrev?.();
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    onNext?.();
  };

  const renderPreview = () => {
    try {
      const fileName = decodeURIComponent(currentFile).split('/').pop().split('?')[0];
      const fileExtension = fileName.split('.').pop().toLowerCase();

      const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
      const isVideo = /\.(mp4|mov|webm|avi|mkv)$/i.test(fileName);
      const isAudio = /\.(mp3|wav|ogg|m4a)$/i.test(fileName);

      if (isImage) {
        return (
          <img
            src={currentFile}
            alt={fileName}
            style={{
              maxWidth: '90%',
              maxHeight: '90vh',
              objectFit: 'contain'
            }}
          />
        );
      }

      if (isVideo) {
        return (
          <video
            controls
            autoPlay
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          >
            <source src={currentFile} type={`video/${fileExtension}`} />
            Your browser does not support video playback.
          </video>
        );
      }

      if (isAudio) {
        return (
          <audio
            controls
            autoPlay
            style={{
              width: '80%',
              maxWidth: '600px'
            }}
          >
            <source src={currentFile} type={`audio/${fileExtension}`} />
            Your browser does not support audio playback.
          </audio>
        );
      }

      return (
        <Box sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: 3,
          borderRadius: 2
        }}>
          <a 
            href={currentFile}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1976d2',
              textDecoration: 'none',
              fontSize: '1.2rem'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            Open {fileName}
          </a>
        </Box>
      );
    } catch (error) {
      console.error('Error rendering preview:', error);
      return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none', // Remove focus outline
          tabIndex: -1 // Make div focusable for keyboard events
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        >
          <Close />
        </IconButton>

        {/* Navigation arrows */}
        {!isFirstFile && (
          <IconButton
            onClick={handlePrevClick}
            sx={{
              position: 'absolute',
              left: 20,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
              },
              zIndex: 1
            }}
          >
            <ArrowBack />
          </IconButton>
        )}
        
        {!isLastFile && (
          <IconButton
            onClick={handleNextClick}
            sx={{
              position: 'absolute',
              right: 20,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
              },
              zIndex: 1
            }}
          >
            <ArrowForward />
          </IconButton>
        )}

        {/* Center clickable area */}
        <Box
          onClick={handleCenterClick}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          {renderPreview()}
        </Box>
      </Box>
    </Modal>
  );
};

export default FilePreviewModal;