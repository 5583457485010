import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#E3E3E3',
      dark: '#115293',
      backgroundColor: '#BE00DB', // Active background color for NavLink
    },
    text: {
      primary: '#212121',  // Primary text color
      secondary: '#666',    // Secondary text color
    },
    background: {
      input: '#f5f5f5',     // Light background for input fields
    },
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,  // Set the single font for the entire site
    fontSize: 14,  // Base font size
    h1: {
      fontSize: '2.5rem',  // Adjust heading font sizes
    },
    h2: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: '1.2rem',  // Adjust body font size
    },
    button: {
      fontSize: '1.2rem',  // Font size for buttons
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,  // Rounded corners for buttons
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: 'rgba(100, 0, 255, 0.8)', // Hover effect for buttons
          },
          '&.active': {
            backgroundColor: '#0B3A64', // Active button state color
            fontWeight: 'bold',
          },
          transition: 'background-color 0.3s ease',  // Smooth transition for background color
          bgcolor: '#e0f7fa',  // Default background color
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `'Roboto', sans-serif`, // Ensure all Typography components use the font
          '&.active': {
            fontWeight: 'bold',  // Make active text bold
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '40px',  // Rounded corners for all text fields
            bgcolor: 'grey.500',  // Set background color to grey
            backgroundColor: '#DBDBDB',  // Set background color to light gray
            padding: 4,
            fontFamily: `'Roboto', sans-serif`,  // Apply the font to input fields
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',  // Remove the border/outline
            },
            '& input': {
              padding: '10px',  // Adjust padding as needed
            },
          },
        },
      },
    },
    MuiNavLink: {
      styleOverrides: {
        root: {
          fontFamily: `'Roboto', sans-serif`,  // Ensure NavLink uses the same font
          '&.active': {
            backgroundColor: '#0B3A64',  // Active state background color for NavLink
            fontWeight: 'bold',
            color: '#115293',  // Active state text color
          },
        },
      },
    },
  },
});

// Enable responsive font sizes for different screen sizes
theme = responsiveFontSizes(theme);

export default theme;
