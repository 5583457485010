import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress, useMediaQuery, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Chat from '../components/Chat.js';
import axiosInstance from '../services/api.js';

const Dashboard = () => {
  const { user, accessToken, isAuthenticated, loading: authLoading, logout } = useAuth();
  const [channels, setChannels] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loadingState, setLoadingState] = useState({
    channels: true,
    initialLoad: true,
    error: null,
  });
  const isMobile = useMediaQuery('(max-width: 600px)');
  const mainContentRef = useRef(null);
  const messagesEndRef = useRef(null);
  const controllerRef = useRef(null);

  // Enhanced authentication state check
  const isFullyAuthenticated = () => {
    const userId = user?._id || user?.id || user?.email; // Fallback to `id` or `email`
    const fullyAuthenticated = isAuthenticated && !!accessToken && !!userId;
    console.log('Checking full authentication:', { isAuthenticated, accessToken, userId, fullyAuthenticated });
    return fullyAuthenticated;
  };

  // Safe API call wrapper with cancellation handling
  const safeApiCall = async (fn, errorMessage) => {
    try {
      controllerRef.current = new AbortController();
      return await fn();
    } catch (error) {
      if (axiosInstance.isCancel(error)) {
        console.log('Request canceled:', error.message);
        return null;
      }
      console.error(errorMessage, error);
      if (error?.response?.status === 401) logout();
      throw error;
    }
  };

  // Fetch user channels with validation
  const fetchUserChannels = async () => {
    if (!isFullyAuthenticated()) {
      throw new Error('Invalid authentication state');
    }

    return safeApiCall(
      async () => {
        const response = await axiosInstance.get(`/api/channels/${user._id || user.id}`, {
          signal: controllerRef.current?.signal,
        });

        // Validate response structure
        if (!response.data || !Array.isArray(response.data.channels)) {
          throw new Error('Invalid response structure from channels API');
        }

        // Validate channels array
        if (response.data.channels.length === 0) {
          console.warn('No channels found for the user');
        }

        return response.data.channels;
      },
      'Failed to fetch channels'
    );
  };

  // Restore last channel with validation
  const restoreLastChannel = async (channels) => {
    if (!channels.length || !isFullyAuthenticated()) return null;
  
    try {
      const response = await axiosInstance.get(
        `/api/users/${user._id}/lastActiveChannel`,
        { signal: controllerRef.current?.signal }
      );
  
      if (response.data?.lastActiveChannel) {
        const lastChannel = channels.find((c) => c._id === response.data.lastActiveChannel);
        if (lastChannel) return lastChannel;
      }
  
      // If no last channel found or it's invalid, return first channel
      console.log('No valid last active channel found, defaulting to first channel');
      return channels[0];
    } catch (error) {
      // Handle 404 gracefully
      if (error.response?.status === 404) {
        console.log('No last active channel set, defaulting to first channel');
        return channels[0];
      }
      console.error('Error restoring last channel:', error);
      return channels[0];
    }
  };

  // Initialization effect
  useEffect(() => {
    const initDashboard = async () => {
      try {
        if (!isFullyAuthenticated()) {
          console.error('User is not fully authenticated');
          return;
        }

        setLoadingState((prev) => ({ ...prev, error: null }));

        // Fetch channels
        const channels = await fetchUserChannels();
        if (!channels) {
          console.error('Channels fetch was canceled or failed');
          return;
        }

        // Validate channels
        if (!Array.isArray(channels)) {
          throw new Error('Invalid channels data received');
        }

        setChannels(channels);

        // Restore last channel
        if (channels.length > 0) {
          const lastChannel = await restoreLastChannel(channels);
          setSelectedChannel(lastChannel);
        } else {
          console.warn('No channels available for the user');
        }
      } catch (error) {
        console.error('Dashboard initialization failed:', error);

        if (error.message !== 'Invalid authentication state') {
          setLoadingState((prev) => ({
            ...prev,
            error: error.response?.data?.message || error.message || 'Failed to initialize dashboard',
            channels: false,
          }));
        }
      } finally {
        setLoadingState((prev) => ({
          ...prev,
          initialLoad: false,
          channels: false,
        }));
      }
    };

    initDashboard();

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [isAuthenticated, accessToken, user?._id]);

  // Layout effects
  useEffect(() => {
    if (isMobile && selectedChannel) {
      setSidebarOpen(false);
    }
  }, [selectedChannel, isMobile]);

  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (mainContent) mainContent.inert = isSidebarOpen && isMobile;
  }, [isSidebarOpen, isMobile]);

  useEffect(() => {
    if (selectedChannel) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedChannel]);

  // Handlers
  const handleChannelSelect = async (channel) => {
    try {
      if (!channel || !channel._id || !isFullyAuthenticated()) {
        throw new Error('Invalid channel or authentication state');
      }

      setSelectedChannel(channel);

      await safeApiCall(
        async () => {
          await axiosInstance.put(
            `/api/users/${user._id}/lastActiveChannel`,
            { lastActiveChannel: channel._id },
            { signal: controllerRef.current.signal }
          );
        },
        'Failed to update last channel'
      );
    } catch (error) {
      console.error('Error updating last active channel:', error);
    }
  };

  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  // Loading and error states
  if (loadingState.initialLoad || authLoading) {
    return (
      <Box sx={fullPageLoaderStyle}>
        <CircularProgress />
        <Typography variant="body1" sx={{ ml: 2 }}>
          {isFullyAuthenticated() ? 'Loading dashboard...' : 'Verifying session...'}
        </Typography>
      </Box>
    );
  }

  if (!isFullyAuthenticated()) {
    return (
      <Box sx={fullPageCenterStyle}>
        <Typography variant="h6" color="error" sx={{ mb: 2 }}>
          Session expired or invalid
        </Typography>
        <Button variant="contained" onClick={logout}>
          Return to Login
        </Button>
      </Box>
    );
  }

  if (loadingState.error) {
    return (
      <Box sx={fullPageCenterStyle}>
        <Typography variant="h6" color="error" sx={{ mb: 2 }}>
          {loadingState.error}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Please check your connection or contact support if the issue persists.
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '85vh', overflow: 'hidden' }}>
      <Header toggleSidebar={toggleSidebar} selectedChannel={selectedChannel} />

      <Box sx={sidebarStyle(isSidebarOpen)}>
        <Sidebar
          selectedChannel={selectedChannel}
          onChannelSelect={handleChannelSelect}
          channels={channels}
          loading={loadingState.channels}
        />
      </Box>

      <Box id="main-content" ref={mainContentRef} sx={mainContentStyle(isSidebarOpen, isMobile)}>
        {selectedChannel ? (
          <Chat
            channel={selectedChannel}
            isSidebarOpen={isSidebarOpen}
            messagesEndRef={messagesEndRef}
          />
        ) : (
          <Typography variant="h6" align="center" color="textSecondary">
            {channels.length === 0 ? 'No channels available' : 'Select a channel to start chatting'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

// Style blocks
const fullPageLoaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const fullPageCenterStyle = {
  ...fullPageLoaderStyle,
  flexDirection: 'column',
};

const sidebarStyle = (open) => ({
  position: 'fixed',
  top: 60,
  left: open ? 0 : -250,
  width: 250,
  bgcolor: 'grey.200',
  height: '100vh',
  transition: 'left 0.3s ease',
  zIndex: 10,
});

const mainContentStyle = (open, mobile) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  transition: 'margin-left 0.3s ease',
  marginLeft: open ? (mobile ? '250px' : '250px') : 0,
  width: '100%',
});

export default Dashboard;