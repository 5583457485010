import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api.js';

// Create the AuthContext
const AuthContext = createContext(null);

// Constants
const TOKEN_CHECK_INTERVAL = 10000; // Check token expiration every 10 seconds
const TOKEN_REFRESH_THRESHOLD = 30000; // Refresh token 30 seconds before expiration

// Helper function to safely get and parse stored values from localStorage
const getStoredValue = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error reading ${key} from storage:`, error);
    return null;
  }
};

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const checkIntervalRef = useRef(null); // Ref to store the interval ID

  // Initial state
  const [state, setState] = useState({
    user: getStoredValue('user'), // User data
    isAuthenticated: false, // Authentication status
    loading: true, // Loading state
    accessToken: localStorage.getItem('accessToken'), // Access token
    accessTokenExpiration: getStoredValue('accessTokenExpiration'), // Access token expiration
    refreshTokenExpiration: getStoredValue('refreshTokenExpiration'), // Refresh token expiration
    error: null, // Error message
    isRefreshing: false, // Token refresh in progress
  });

  // Function to update the auth state
  const updateAuthState = useCallback((updates) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  // Function to handle session expiration
  const handleSessionExpired = useCallback(() => {
    localStorage.clear(); // Clear all stored data
    axiosInstance.defaults.headers.common['Authorization'] = ''; // Remove authorization header

    // Update state to reflect session expiration
    updateAuthState({
      user: null,
      isAuthenticated: false,
      loading: false,
      accessToken: null,
      accessTokenExpiration: null,
      refreshTokenExpiration: null,
      error: 'Session expired. Please log in again.',
      isRefreshing: false,
    });

    // Display a message instead of navigating to login
    console.log('Session expired. Please log in again.');
  }, [updateAuthState]);

  /*
  // Function to refresh tokens (commented out for testing)
  const refreshTokens = useCallback(async () => {
    if (state.isRefreshing) {
      console.log('Token generation already in progress');
      return false;
    }

    try {
      updateAuthState({ isRefreshing: true });
      console.log('Attempting to refresh tokens');

      // Ensure userId is available
      const userId = state.user?._id;
      if (!userId) {
        throw new Error('User ID is required to generate tokens');
      }

      // Send a request to refresh tokens
      const response = await axiosInstance.post(
        '/api/auth/generate-tokens',
        { userId }, // Pass userId in the request body
        { withCredentials: true } // Include cookies
      );

      const { accessToken, accessTokenExpiration } = response.data;

      // Update localStorage with new tokens
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));

      // Update axios headers with the new access token
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Update state with new tokens
      updateAuthState({
        accessToken,
        accessTokenExpiration,
        isAuthenticated: true,
        isRefreshing: false,
        error: null,
      });

      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      if (error.response?.status === 401) {
        handleSessionExpired(); // Handle session expiration
      }
      return false;
    } finally {
      updateAuthState({ isRefreshing: false });
    }
  }, [state.isRefreshing, state.user, updateAuthState, handleSessionExpired]);
  */

  /*
  // Function to generate new tokens (commented out for testing)
  const generateTokens = useCallback(async () => {
    const userId = state.user?._id;
    if (!userId || !state.accessToken) {
      console.error('Cannot generate tokens: missing user ID or access token');
      return false;
    }

    try {
      console.log('Generating new tokens for user:', userId);

      // Send a request to generate new tokens
      const response = await axiosInstance.get('/api/auth/generate-tokens', {
        params: { userId },
        headers: { Authorization: `Bearer ${state.accessToken}` },
        withCredentials: true,
      });

      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      // Update localStorage with new tokens
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      if (user) localStorage.setItem('user', JSON.stringify(user));

      // Update axios headers with the new access token
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Update state with new tokens
      updateAuthState({
        user: user || state.user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        error: null,
      });

      return true;
    } catch (error) {
      console.error('Token generation failed:', error);
      if (error.response?.status === 401) {
        handleSessionExpired(); // Handle session expiration
      }
      return false;
    }
  }, [state.user, state.accessToken, updateAuthState, handleSessionExpired]);
  */

  // Effect to check token expiration periodically
  useEffect(() => {
    const setupTokenCheck = () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current); // Clear existing interval
      }

      if (!state.accessTokenExpiration || !state.isAuthenticated || state.isRefreshing) {
        return; // Skip if no token or already refreshing
      }

      // Function to check token expiration
      const checkTokenExpiration = async () => {
        const timeUntilExpiry = state.accessTokenExpiration - Date.now();

        // Refresh token if it's about to expire
        if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD && !state.isRefreshing) {
          console.log('Token expiring soon, refreshing...');
          // await refreshTokens(); // Commented out for testing
        }
      };

      // Set up the interval
      checkIntervalRef.current = setInterval(checkTokenExpiration, TOKEN_CHECK_INTERVAL);
    };

    // Commented out for testing purposes
    // setupTokenCheck();

    // Cleanup interval on unmount
    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [state.accessTokenExpiration, state.isAuthenticated, state.isRefreshing]);

  // Effect to initialize auth state on mount
  useEffect(() => {
    const validateSession = async () => {
      const storedToken = localStorage.getItem('accessToken');
      const storedExpiration = getStoredValue('accessTokenExpiration');

      if (!storedToken || !storedExpiration) {
        updateAuthState({ loading: false });
        return; // Skip if no token or expiration
      }

      // Set axios headers with the stored token
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

      try {
        // Check if the token is expired
        if (storedExpiration <= Date.now()) {
          console.log('Stored token expired, attempting refresh');
          // const refreshSuccess = await refreshTokens(); // Commented out for testing
          // if (!refreshSuccess) {
          //   throw new Error('Token refresh failed');
          // }
          return;
        }

        // Fetch user data if the token is valid
        const response = await axiosInstance.get('/api/users/me');
        if (!response.data?._id) {
          throw new Error('Invalid user data');
        }

        // Update state with user data
        updateAuthState({
          user: response.data,
          accessToken: storedToken,
          accessTokenExpiration: storedExpiration,
          isAuthenticated: true,
          loading: false,
          error: null,
        });
      } catch (error) {
        console.error('Session validation failed:', error);
        handleSessionExpired(); // Handle session expiration
      }
    };

    validateSession();
  }, [updateAuthState, handleSessionExpired]);

  // Effect to add axios interceptor for token updates
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Add token to headers
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Cleanup interceptor on unmount
    return () => {
      axiosInstance.interceptors.request.eject(interceptor);
    };
  }, []);

  // Function to handle login
  const login = async (email, password) => {
    try {
      updateAuthState({ loading: true, error: null });

      // Send login request
      const response = await axiosInstance.post('/api/users/login', { email, password });
      const { accessToken, accessTokenExpiration, refreshTokenExpiration, user } = response.data;

      // Update localStorage with new tokens
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiration', JSON.stringify(accessTokenExpiration));
      localStorage.setItem('refreshTokenExpiration', JSON.stringify(refreshTokenExpiration));
      localStorage.setItem('user', JSON.stringify(user));

      // Update axios headers with the new access token
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // Update state with new tokens
      updateAuthState({
        user,
        accessToken,
        accessTokenExpiration,
        refreshTokenExpiration,
        isAuthenticated: true,
        loading: false,
        error: null,
      });

      return true;
    } catch (error) {
      updateAuthState({
        loading: false,
        error: error.response?.data?.message || 'Login failed',
        isAuthenticated: false,
      });
      return false;
    }
  };

  // Function to handle logout
  const logout = useCallback(async () => {
    try {
      await axiosInstance.post('/api/auth/logout', null, { withCredentials: true });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.clear(); // Clear all stored data
      axiosInstance.defaults.headers.common['Authorization'] = ''; // Remove authorization header
      updateAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,
        error: null,
        isRefreshing: false,
      });
      navigate('/login', { replace: true });
    }
  }, [navigate, updateAuthState]);

  // Value to be provided by the context
  const value = {
    ...state,
    login,
    logout,
    // refreshTokens, // Commented out for testing
    // generateTokens, // Commented out for testing
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;