import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Paper, Typography, Box, Divider, Button, Snackbar, Alert,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../contexts/AuthContext.js';
import axiosInstance from '../services/api.js';

const TokenTester = () => {
  const { 
    user, 
    accessToken, 
    isAuthenticated,
    isLoading: authLoading,
    accessTokenExpiration: contextTokenExpiration,
    refreshTokenExpiration: contextRefreshExpiration,
    refreshTokens: contextRefreshTokens,
    error: authError
  } = useAuth();
  
  const [logs, setLogs] = useState([]);
  const [requestHeaders, setRequestHeaders] = useState(null);
  const [cookies, setCookies] = useState({});
  const [response, setResponse] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Detailed token info logging
  const tokenInfo = useMemo(() => ({
    accessToken: localStorage.getItem('accessToken'),
    accessTokenExpiration: localStorage.getItem('accessTokenExpiration'),
    refreshTokenExpiration: localStorage.getItem('refreshTokenExpiration'),
    contextTokenExpiration,
    contextRefreshExpiration
  }), [contextTokenExpiration, contextRefreshExpiration]);

  // Add log helper
  const addLog = useCallback((type, message, details = null) => {
    const timestamp = new Date().toISOString();
    setLogs(prev => [...prev, {
      type,
      message: typeof message === 'object' ? JSON.stringify(message, null, 2) : message,
      details: details ? JSON.stringify(details, null, 2) : null,
      timestamp
    }]);
    
    // Also log to console for debugging
    console.log(`[${timestamp}] ${type}:`, message, details || '');
  }, []);

  // Enhanced auth validation
  const authState = useMemo(() => {
    const userId = user?._id || user?.id;
    const now = Date.now();
    
    const tokenExpirationTime = contextTokenExpiration || 
      (tokenInfo.accessTokenExpiration ? JSON.parse(tokenInfo.accessTokenExpiration) : null);
    
    const refreshExpirationTime = contextRefreshExpiration ||
      (tokenInfo.refreshTokenExpiration ? JSON.parse(tokenInfo.refreshTokenExpiration) : null);

    const reasons = [];
    if (!isAuthenticated) reasons.push('not authenticated');
    if (!userId) reasons.push('missing user ID');
    if (!accessToken) reasons.push('missing access token');
    if (!tokenExpirationTime) reasons.push('missing token expiration');
    if (tokenExpirationTime && tokenExpirationTime < now) reasons.push('token expired');

    const validationDetails = {
      isAuthenticated,
      hasUser: !!user,
      hasUserId: !!userId,
      hasAccessToken: !!accessToken,
      hasTokenExpiration: !!tokenExpirationTime,
      tokenExpired: tokenExpirationTime ? tokenExpirationTime < now : null,
      currentTime: now,
      expirationTime: tokenExpirationTime
    };

    addLog('debug', 'Auth state validation:', validationDetails);

    return {
      isValid: reasons.length === 0,
      reasons,
      userId,
      tokenExpirationTime: tokenExpirationTime ? new Date(tokenExpirationTime) : null,
      refreshExpirationTime: refreshExpirationTime ? new Date(refreshExpirationTime) : null,
      message: reasons.length > 0 ? `Authentication invalid: ${reasons.join(', ')}` : null,
      details: validationDetails
    };
  }, [isAuthenticated, user, accessToken, contextTokenExpiration, contextRefreshExpiration, tokenInfo, addLog]);

  // Helpers
  const clearLogs = useCallback(() => {
    setLogs([]);
    setRequestHeaders(null);
    setResponse(null);
    setCookies({});
  }, []);

  const updateCookies = useCallback(async () => {
    try {
      addLog('info', '🍪 Fetching current cookies');
      const response = await axiosInstance.get('/api/check-cookies', {
        withCredentials: true
      });
      setCookies(response.data.cookies);
      addLog('info', 'Cookies retrieved:', response.data.cookies);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        addLog('error', 'Missing or invalid cookies:', error.response.data);
      } else {
        addLog('error', 'Failed to fetch cookies:', error);
      }
    }
  }, [addLog]);

  const handleApiError = useCallback((error, action) => {
    const errorDetails = {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    };

    addLog('error', `❌ Error ${action}:`, errorDetails);
    
    if (error.response?.status === 401) {
      addLog('warning', '🔒 Authentication expired or invalid');
    }

    setSnackbar({
      open: true,
      message: error.response?.data?.message || error.message,
      severity: 'error'
    });
  }, [addLog]);

  // Token operations
  const testTokenGeneration = async () => {
    if (!authState.isValid) {
      setSnackbar({
        open: true,
        message: authState.message,
        severity: 'warning'
      });
      return;
    }
  
    try {
      addLog('info', '🚀 Starting token generation');
      const response = await axiosInstance.post('/api/auth/generate-tokens', { userId: authState.userId }, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
        withCredentials: true
      });
  
      setRequestHeaders(response.config.headers);
      setResponse(response.data);
      await updateCookies();
  
      addLog('success', '✅ Tokens generated successfully');
      addLog('info', 'Response data:', response.data);
  
      if (response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('accessTokenExpiration', 
          JSON.stringify(response.data.accessTokenExpiration));
      }
  
      setSnackbar({
        open: true,
        message: 'Tokens generated successfully',
        severity: 'success'
      });
    } catch (error) {
      handleApiError(error, 'generating tokens');
    }
  };

  const testRefreshToken = async () => {
    try {
      addLog('info', '🔄 Starting token refresh');
      const response = await axiosInstance.post('/api/auth/refresh-token', null, {
        withCredentials: true, // Include cookies in the request
      });
  
      setRequestHeaders(response.config.headers);
      setResponse(response.data);
      await updateCookies();
  
      addLog('success', '✅ Tokens refreshed successfully');
      addLog('info', 'Response data:', response.data);
  
      if (response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('accessTokenExpiration', JSON.stringify(response.data.accessTokenExpiration));
      }
  
      setSnackbar({
        open: true,
        message: 'Tokens refreshed successfully',
        severity: 'success',
      });
    } catch (error) {
      handleApiError(error, 'refreshing tokens');
    }
  };



  const handleCopyAll = async (data) => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
      setSnackbar({
        open: true,
        message: 'Copied to clipboard',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to copy to clipboard',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Effects
  useEffect(() => {
    const logInitialState = async () => {
      clearLogs();
      
      addLog('info', '🔍 Checking authentication state', {
        isLoading: authLoading,
        isAuthenticated,
        hasUser: !!user,
        userId: user?._id || user?.id,
        email: user?.email,
        hasAccessToken: !!accessToken,
        tokenExpiration: authState.tokenExpirationTime?.toISOString(),
        refreshExpiration: authState.refreshExpirationTime?.toISOString(),
        authError
      });

      if (authState.isValid) {
        addLog('success', '✅ Authentication valid');
      } else {
        addLog('warning', '⚠️ Authentication incomplete');
        authState.reasons.forEach(reason => addLog('error', `❌ ${reason}`));
      }

      await updateCookies();
    };

    logInitialState();
  }, [authState, user, accessToken, isAuthenticated, authLoading, authError, addLog, clearLogs, updateCookies]);

  useEffect(() => {
    if (!authState.tokenExpirationTime) return;

    const timer = setInterval(() => {
      const now = new Date();
      const timeLeft = authState.tokenExpirationTime - now;
      
      if (timeLeft <= 0) {
        clearInterval(timer);
        setTimeRemaining('Session expired');
        addLog('warning', '⚠️ Token has expired');
      } else {
        const minutes = Math.floor(timeLeft / 1000 / 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        setTimeRemaining(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [authState.tokenExpirationTime, addLog]);

  // Loading state
  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Render
  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      {/* Enhanced status header */}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Token Testing Dashboard</Typography>
        <Box sx={{ 
          p: 1, 
          borderRadius: 1, 
          bgcolor: authState.isValid ? 'success.light' : 'error.light',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          {isRefreshing ? (
            <CircularProgress size={20} color="inherit" />
          ) : authState.isValid ? (
            <>
              <span>✓ Fully Authenticated</span>
              {timeRemaining && (
                <Typography variant="caption" sx={{ opacity: 0.8 }}>
                  ({timeRemaining})
                </Typography>
              )}
            </>
          ) : (
            <span>✗ Authentication Invalid</span>
          )}
        </Box>
      </Box>

      {/* Enhanced user info section */}
      {user && (
        <Box sx={{ mb: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
          <Typography variant="body2">Email: {user.email}</Typography>
          <Typography variant="body2">ID: {authState.userId || 'Missing'}</Typography>
          <Typography variant="body2">
            Access Token: {accessToken ? (
              <span style={{ color: 'green' }}>✓ Present</span>
            ) : (
              <span style={{ color: 'red' }}>✗ Missing</span>
            )}
          </Typography>
          <Typography variant="body2">
            Token Expires: {authState.tokenExpirationTime?.toLocaleString() || 'Unknown'}
          </Typography>
          <Typography variant="body2" color={
            timeRemaining === 'Session expired' ? 'error.main' : 'text.secondary'
          }>
            Time Remaining: {timeRemaining || 'Unknown'}
          </Typography>
          {authError && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Error: {authError}
            </Typography>
          )}
        </Box>
      )}

      {/* Action Buttons */}
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={testTokenGeneration}
          disabled={!authState.isValid || isRefreshing}
        >
          Generate Tokens
        </Button>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={testRefreshToken}
          disabled={!authState.isValid || isRefreshing}
        >
          Refresh Tokens
        </Button>
        <Button
          variant="outlined"
          startIcon={<ContentCopyIcon />}
          onClick={() => handleCopyAll(response)}
          disabled={!response}
        >
          Copy Response
        </Button>
      </Box>

      {/* Logs and Response Sections */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}><strong>Request Headers</strong></Typography>
        <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', maxHeight: '200px', overflowY: 'auto' }}>
          {requestHeaders ? JSON.stringify(requestHeaders, null, 2) : 'No headers logged'}
        </pre>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}><strong>Cookies</strong></Typography>
        <TableContainer component={Paper} sx={{ maxHeight: '200px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Value</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(cookies).map(([name, value]) => (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}><strong>Response</strong></Typography>
        <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', maxHeight: '200px', overflowY: 'auto' }}>
          {response ? JSON.stringify(response, null, 2) : 'No response logged'}
        </pre>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}><strong>Console Logs</strong></Typography>
        <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', maxHeight: '200px', overflowY: 'auto' }}>
          {logs.map((log, index) => (
            <div 
              key={index} 
              className={`mb-1 ${
                log.type === 'error' ? 'text-red-600' :
                log.type === 'success' ? 'text-green-600' :
                log.type === 'warning' ? 'text-yellow-600' :
                'text-gray-800'
              }`}
            >
              <span className="text-gray-500 text-xs">
                {new Date(log.timestamp).toLocaleTimeString()}
              </span>
              {' '}
              {log.message}
            </div>
          ))}
        </pre>
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default TokenTester;