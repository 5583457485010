import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  CardContent,
  Popover,
  MenuItem,
  ButtonBase,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useMediaQuery } from '@mui/material';
import axiosInstance from '../services/api.js';

const UserChannels = ({ onChannelSelect, selectedChannel }) => {
  const { user, isAuthenticated, loading: authLoading, error: authError } = useAuth();
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newChannelName, setNewChannelName] = useState('');
  const [newChannelDescription, setNewChannelDescription] = useState('');
  const [currentChannel, setCurrentChannel] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [channelToDelete, setChannelToDelete] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const setLastActiveChannel = async (userId, channelId) => {
    try {
      const response = await axiosInstance.put(`/api/users/${userId}/lastActiveChannel`, { lastActiveChannel: channelId });
      console.log('Last active channel updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating last active channel:', error);
    }
  };
  
  // Function to handle channel switch
  const handleChannelSwitch = async (userId, newChannelId) => {
    // Perform any necessary operations for switching channels
    // ...
  
    // Update the last active channel
    await setLastActiveChannel(userId, newChannelId);
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (isAuthenticated && user && user._id) {
      fetchUserChannels();
    }
  }, [isAuthenticated, user]);

  const fetchUserChannels = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/channels/${user._id}`);
      setChannels(response.data.channels);
    } catch (error) {
      console.error('Error fetching channels:', error);
      setError('Failed to fetch channels.');
    } finally {
      setLoading(false);
    }
  };

  const handleChannelClick = (channel) => {
    setCurrentChannel(channel);
    onChannelSelect(channel);
    handleChannelSwitch(user._id, channel._id);
  };

  const handleCreateChannel = async () => {
    if (!newChannelName) {
      setError('Channel name is required.');
      return;
    }

    const newChannel = {
      name: newChannelName,
      description: newChannelDescription,
      creator_id: user._id,
    };

    try {
      const response = await axiosInstance.post(`/api/channels/${user._id}`, newChannel);
      if (response.status === 201) {
        setChannels((prevChannels) => [...prevChannels, response.data.channel]);
        setNewChannelName('');
        setNewChannelDescription('');
        setOpenModal(false);
      } else {
        throw new Error('Failed to create channel');
      }
    } catch (error) {
      setError('Failed to create channel.');
    }
  };

  const handleEditChannel = async () => {
    if (!newChannelName) {
      setError('Channel name is required.');
      return;
    }

    const updatedChannel = {
      ...currentChannel,
      name: newChannelName,
      description: newChannelDescription,
    };

    try {
      const response = await axiosInstance.put(`/api/channels/${currentChannel._id}`, updatedChannel);
      const updatedChannels = channels.map((channel) =>
        channel._id === currentChannel._id ? response.data.channel : channel
      );
      setChannels(updatedChannels);
      setOpenEditModal(false);
      setCurrentChannel(response.data.channel);
    } catch (error) {
      setError('Failed to update channel.');
    }
  };

  const handleDeleteChannel = async () => {
    if (!channelToDelete) {
      console.error("No channel selected for deletion");
      return;
    }

    try {
      const response = await axiosInstance.delete(`/api/channels/${user._id}/${channelToDelete._id}`);
      setChannels(channels.filter((channel) => channel._id !== channelToDelete._id));
      setOpenDeleteConfirmation(false);
    } catch (error) {
      setError('Failed to delete channel.');
      setOpenDeleteConfirmation(false);
    }
  };

  const handleClickPopover = (event, channel) => {
    setAnchorEl(event.currentTarget);
    setCurrentChannel(channel);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenEditModal = (channel) => {
    setNewChannelName(channel.name);
    setNewChannelDescription(channel.description);
    setCurrentChannel(channel);
    setOpenEditModal(true);
  };

  const handleConfirmEdit = () => {
    setOpenEditModal(false);
    handleEditChannel();
  };

  if (authLoading || loading) return <CircularProgress />;

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      {!isMobile ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          fullWidth
          sx={{ mb: 2 }}
        >
          + Channel
        </Button>
      ) : (
        <IconButton color="primary" onClick={() => setOpenModal(true)} sx={{ mb: 2 }}>
          <AddIcon />
        </IconButton>
      )}

      {channels.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {channels.map((channel) => (
            <Box key={channel._id} sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <ButtonBase
                sx={{
                  width: '100%',
                  boxShadow: 1,
                  borderRadius: 10,
                  backgroundColor: selectedChannel?._id === channel._id ? 'rgba(0, 0, 0, 0.08)' : 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                  display: 'block',
                  textAlign: 'left',
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleChannelClick(channel)}
              >
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      {channel.name} {channel.isDefault && '(Default)'}
                    </Typography>
                  </Box>
                </CardContent>
              </ButtonBase>

              <IconButton
                onClick={(e) => handleClickPopover(e, channel)}
                color="primary"
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <MoreVertIcon />
              </IconButton>

              <Popover
                open={Boolean(anchorEl) && currentChannel?._id === channel._id}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleOpenEditModal(channel)}>Edit</MenuItem>
                {!channel.isDefault && (
                  <MenuItem onClick={() => { setChannelToDelete(channel); setOpenDeleteConfirmation(true); }}>Delete</MenuItem>
                )}
              </Popover>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>No channels available.</Typography>
      )}

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Create New Channel</DialogTitle>
        <DialogContent>
          <TextField
            label="Channel Name"
            fullWidth
            value={newChannelName}
            onChange={(e) => setNewChannelName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Channel Description"
            fullWidth
            value={newChannelDescription}
            onChange={(e) => setNewChannelDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleCreateChannel}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <DialogTitle>Edit Channel</DialogTitle>
        <DialogContent>
          <TextField
            label="Channel Name"
            fullWidth
            value={newChannelName}
            onChange={(e) => setNewChannelName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Channel Description"
            fullWidth
            value={newChannelDescription}
            onChange={(e) => setNewChannelDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditModal(false)}>Cancel</Button>
          <Button onClick={handleConfirmEdit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this channel?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={handleDeleteChannel} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserChannels;