import React, { useState, useEffect } from 'react';
import { Box, IconButton, Snackbar, TextareaAutosize } from '@mui/material';
import { useTheme } from '@mui/material/styles/index.js';
import SendIcon from '@mui/icons-material/Send';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import axiosInstance from '../services/api.js';
import { useUploadQueue } from '../contexts/UploadQueueContext.js';
import { styled } from '@mui/material/styles/index.js';

const ChatInput = ({ user, currentChannel, setMessages, setSnackbarOpen, onMessageSent }) => {
  const [message, setMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hasFiles, setHasFiles] = useState(false); // State to track if there are files in the queue
  const theme = useTheme(); // Access the theme
  const { addFilesToQueue, uploadQueue, uploadFiles, fileUrls } = useUploadQueue(); // Include fileUrls from context

  // Update the `hasFiles` state whenever the uploadQueue changes
  useEffect(() => {
    setHasFiles(uploadQueue.length > 0);
  }, [uploadQueue]);

  const handleSendMessage = async () => {
    const trimmedMessage = message.trim();
    if (!trimmedMessage && !hasFiles) return;

    try {
      const uploadedFileUrls = await uploadFiles();

      const messageData = {
        text: trimmedMessage || '',
        fileUrls: uploadedFileUrls.length > 0 ? uploadedFileUrls : [],
      };

      const response = await axiosInstance.post(
        `/api/message/channel/${currentChannel?._id}/send`,
        messageData
      );

      if (response?.data?.success) {
        const newMessage = {
          _id: response.data.data._id, // Use the actual MongoDB ObjectId
          channel_id: currentChannel._id,
          user_id: user._id,
          content: trimmedMessage || '',
          fileUrls: uploadedFileUrls,
          timestamp: new Date(),
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setMessage('');
        onMessageSent?.();
      } else {
        setSnackbarMessage(response?.data?.message || 'Failed to send message');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error sending message.';
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    addFilesToQueue(selectedFiles, user?._id, currentChannel?._id, currentChannel?.name);
  };

  const isSendButtonDisabled = !message.trim() && !hasFiles;

  return (
    <Box padding="5px" border="1px solid #ddd">
      <Box display="flex" alignItems="center" gap={2}>
        <IconButton component="label">
          <PhotoCamera />
          <input
            type="file"
            accept="image/*,video/*,.pdf,.doc,.docx"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </IconButton>

        <TextareaAutosize
          minRows={1}
          maxRows={5}
          style={{
            width: '100%',
            resize: 'none',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '20px',
            border: '1px solid #ccc',
            fontSize: theme.typography.body1.fontSize, // Use font size from theme
            fontFamily: theme.typography.body1.fontFamily, // Use font family from theme
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
          onKeyDown={handleKeyDown}
        />

        <IconButton
          onClick={handleSendMessage}
          style={{
            color: isSendButtonDisabled ? '#bbb' : 'white',
            backgroundColor: isSendButtonDisabled ? 'transparent' : '#0056b3',
            transition: 'background-color 0.3s, color 0.3s',
          }}
          disabled={isSendButtonDisabled}
        >
          <SendIcon />
        </IconButton>
      </Box>

      <Snackbar
        open={snackbarMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ChatInput;