import React, { useState, useEffect } from 'react';
import { useUploadQueue } from '../contexts/UploadQueueContext';
import {
  Box,
  LinearProgress,
  Typography,
  Paper,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Icon for minimizing
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanding
import InsertDriveFile from '@mui/icons-material/InsertDriveFile'; // Import InsertDriveFile icon
import { useTheme } from '@mui/material/styles';

const UploadQueue = () => {
  const {
    uploadQueue,
    uploadProgress,
    fileUrls,
    errorMessage,
    currentChannel,
    removeFileFromQueue,
    addFileToQueue, // Simulate file addition
  } = useUploadQueue();

  const [isMinimized, setIsMinimized] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // Local visibility state
  const [currentFile, setCurrentFile] = useState(null); // Currently uploading file

  // Material-UI hooks for responsive design
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size

  const getUniqueFileKey = (fileName, index) => `${fileName}-${index}-${Date.now()}`;

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClose = () => {
    setIsVisible(false); // Hide the upload queue locally
  };

  const handleAddFile = () => {
    // Simulate file addition (via camera or any trigger)
    addFileToQueue?.({ name: `file-${Date.now()}` });
    setIsVisible(true); // Reopen the queue when a file is added
  };

  const handleAddFileFromChat = (file) => {
    addFileToQueue(file); // Add the file to the upload queue
    setIsVisible(true); // Reopen the queue
  };

  // Track the currently uploading file
  useEffect(() => {
    const inProgressFile = uploadQueue.find(
      ({ file }) => (uploadProgress[file.name] || 0) < 100
    );
    setCurrentFile(inProgressFile ? inProgressFile.file : null);
  }, [uploadQueue, uploadProgress]);

  useEffect(() => {
    if (uploadQueue.length > 0) {
      setIsVisible(true); // Automatically open the queue if files are added
    }
  }, [uploadQueue]);

  return (
    <>
      {isVisible && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: isMobile ? 60 : 60, // Adjust position for mobile or desktop
            right: isMobile ? 0 : 50,
            left: isMobile ? 10 : 'auto',
            width: isMobile ? '90%' : '300px',
            height: isMinimized ? 'auto' : '50vh', // Adjust height dynamically
            maxHeight: '50vh',
            overflowY: isMinimized ? 'hidden' : 'auto',
            zIndex: 1300,
            transition: 'bottom 0.3s, transform 0.3s',
            transform: isVisible ? 'translateY(0)' : 'translateY(100%)', // Off-screen when hidden
          }}
          elevation={3}
        >
          {/* Panel Header */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'sticky', // Keep the header fixed
              top: 0, // Stick to the top of the container
              zIndex: 10, // Ensure it stays above the scrollable content
              backgroundColor: 'white', // Match the background color
              padding: '10px 0', // Add padding for better spacing
              cursor: 'pointer',
              borderBottom: '1px solid #ddd', // Optional: Add a divider
            }}
            onClick={toggleMinimize} // Toggle minimize/expand when clicking the title
          >
            <IconButton size="small" sx={{ marginRight: 1 }}>
              {isMinimized ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                flex: 1,
                borderBottom: isMinimized ? 'none' : '1px solid red',
                paddingBottom: isMinimized ? 0 : '10px',
              }}
            >
              Upload to channel: {currentChannel?.name || 'No Channel'}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering minimize on close button click
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Minimized View */}
          {isMinimized && currentFile && (
            <Box sx={{ padding: '10px', textAlign: 'center' }}>
              <Typography variant="body2">
                Uploading: {currentFile.name} ({uploadProgress[currentFile.name] || 0}%)
              </Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress[currentFile.name] || 0}
                sx={{ mt: 1 }}
              />
            </Box>
          )}

          {/* Expanded View */}
          {!isMinimized && (
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
              {errorMessage && (
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              )}

              {/* Files in queue */}
              {uploadQueue.map(({ file }) => (
                <Box
                  key={getUniqueFileKey(file.name, 'queue')}
                  marginBottom={2}
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid #ddd',
                    paddingBottom: 1,
                    marginBottom: 1,
                    padding: '10px', // Add padding around the file name box
                  }}
                >
                  <Box flex="0 0 50px" mr={2}>
                    {/* File preview (for images) */}
                    {file.type?.startsWith('image') ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '4px',
                        }}
                      />
                    ) : (
                      <InsertDriveFile fontSize="large" />
                    )}
                  </Box>
                  <Box flex="1">
                    <Typography variant="body2" noWrap>
                      {file.name}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[file.name] || 0}
                      sx={{ my: 1 }}
                    />
                    <Typography variant="caption">
                      {uploadProgress[file.name] || 0}%
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => removeFileFromQueue(file.name)}
                    disabled={uploadProgress[file.name] === 100}
                    sx={{ color: 'red' }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}

              {/* Completed files */}
              {fileUrls.length > 0 && (
                <Box mt={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Completed Uploads:
                  </Typography>
                  {fileUrls.map((url, index) => {
                    const filename = url.split('/').pop().split('?')[0];
                    return (
                      <Box
                        key={getUniqueFileKey(url, `completed-${index}`)}
                        mb={1}
                        p={1}
                        bgcolor="background.paper"
                        borderRadius={1}
                      >
                        <Typography variant="caption" component="div" noWrap>
                          {filename}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      )}
    </>
  );
};

export default UploadQueue;
