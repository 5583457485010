import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';
import FilePreviewItem from './FilePreviewItem.js';
import FilePreviewModal from './FilePreviewModal.js';

const ChatDisplay = ({ messages, onDeleteMessage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const messagesEndRef = useRef(null);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };



  

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  

  const handleFileClick = (files, index) => {
    if (Array.isArray(files) && files.length > 0) {
      setCurrentFiles(files);
      setCurrentFileIndex(index);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentFiles([]);
    setCurrentFileIndex(0);
  };

  const handleNext = () => {
    setCurrentFileIndex(prev => Math.min(prev + 1, currentFiles.length - 1));
  };

  const handlePrev = () => {
    setCurrentFileIndex(prev => Math.max(prev - 1, 0));
  };

  const renderFileGrid = (files) => {
    if (!Array.isArray(files) || files.length === 0) return null;

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: '10px',
          gridTemplateColumns: {
            xs: 'repeat(auto-fill, minmax(250px, 1fr))',
            sm: 'repeat(auto-fill, minmax(300px, 1fr))',
            md: 'repeat(auto-fill, minmax(350px, 1fr))'
          },
          width: '100%',
          mb: 2
        }}
      >
        {files.map((fileUrl, index) => (
          <Box 
            onClick={() => handleFileClick(files, index)}
            sx={{ cursor: 'pointer' }}
            key={`${fileUrl}-${index}`}
          >
            <FilePreviewItem
              fileUrl={fileUrl}
              isMobile={isMobile}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box 
      sx={{ 
        paddingTop: '60px', 
        marginLeft: '5px', 
        overflowY: 'auto', 
        flex: 1,
        scrollBehavior: 'smooth' 
      }}
    >
      {messages.map((message) => (
        <Box 
          key={message._id} 
          sx={{ 
            marginBottom: '10px', 
            display: 'flex', 
            alignItems: 'flex-start' 
          }}
        >
          <Box
            sx={{
              flex: 1,
              bgcolor: '#F0F0F0',
              borderRadius: '20px',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 80px)',
            }}
          >
            {message.content && (
              <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                {message.content}
              </Typography>
            )}
            {message.fileUrls && renderFileGrid(message.fileUrls)}
          </Box>
          <Box sx={{ padding: '0', marginLeft: '10px' }}>
            <IconButton
              onClick={() => onDeleteMessage?.(message._id)}
              sx={{ color: '#B8B8B8' }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}

      {/* Invisible element to scroll to */}
      <div ref={messagesEndRef} />

      {modalOpen && currentFiles.length > 0 && (
        <FilePreviewModal
          open={modalOpen}
          onClose={handleCloseModal}
          files={currentFiles}
          currentIndex={currentFileIndex}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </Box>
  );
};

export default ChatDisplay;